import React, { useState, useEffect, useContext, FC } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { default as AppContext } from '../../../../../contexts/AppContext';
import { default as useForceRerender } from '../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../utils/hooks/useObservable';
import isWindowStyles from '../../../../../utils/functions/isWindowStyles';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import { WATCHLIST_SORT_ORDER } from '../../../../../utils/functions/WatchlistUtils';
import authStore from '../../../../../store/authStore';

import styles from './WatchlistHeader.module.scss';

interface WatchlistHeaderProps {
	window?: boolean;
	editLayer: boolean;
	id?: string;
}

const WatchlistHeader: FC<WatchlistHeaderProps> = ({ window, editLayer }) => {
	const appContext = useContext(AppContext);
	const forceRerender = useForceRerender();
	const theme = appContext.appTheme;
	const tradingMode = authStore.use.tradingMode();
	const currentWatchList = watchListStore.use.currentWatchList();
	const getWatchlists = watchListStore.use.getWatchlists();
	const allWatchlists = getWatchlists(tradingMode);
	const getWatchlist = watchListStore.use.getWatchlist();
	const setSortOrder = watchListStore.use.setSortOrder();
	const [sort, setSort] = useState<string>(WATCHLIST_SORT_ORDER.NONE);
	const { t } = useTranslation();

	useObservable(appContext.getPropertyChangeStream('appTheme'), () => forceRerender());

	useEffect(() => {
		const watchlist = getWatchlist(tradingMode, currentWatchList);
		setSort(watchlist ? watchlist.sortOrder : WATCHLIST_SORT_ORDER.NONE);
	}, [tradingMode, currentWatchList, allWatchlists]);

	const handleInstrumentClick = () => {
		let sortOrder: (typeof WATCHLIST_SORT_ORDER)[keyof typeof WATCHLIST_SORT_ORDER] = WATCHLIST_SORT_ORDER.NONE;
		if (sort === WATCHLIST_SORT_ORDER.NONE) {
			sortOrder = WATCHLIST_SORT_ORDER.ASCENDING;
		} else if (sort === WATCHLIST_SORT_ORDER.ASCENDING) {
			sortOrder = WATCHLIST_SORT_ORDER.DESCENDING;
		} else if (sort === WATCHLIST_SORT_ORDER.DESCENDING) {
			sortOrder = WATCHLIST_SORT_ORDER.NONE;
		}
		const watchlist = getWatchlist(tradingMode, currentWatchList);
		if (watchlist) {
			setSortOrder(tradingMode, watchlist.name, sortOrder);
		}
	};

	return (
		<div className={cn(!editLayer ? styles.header : styles.headerEdit)}>
			<div className={cn(styles.watchlistAccordion, styles[`${theme}WatchListAccordion`])}>
				<div
					className={isWindowStyles(
						!editLayer ? styles.instrumentHeader : styles.editInstrument,
						styles.windowInstrument,
						window
					)}
					onClick={() => handleInstrumentClick()}
				>
					{t('en:INSTRUMENT')}
					{!editLayer && sort === WATCHLIST_SORT_ORDER.ASCENDING && (
						<FontAwesomeIcon className={styles.instrumentChevron} icon={['fas', 'angle-up']} size="1x" />
					)}
					{!editLayer && sort === WATCHLIST_SORT_ORDER.DESCENDING && (
						<FontAwesomeIcon className={styles.instrumentChevron} icon={['fas', 'angle-down']} size="1x" />
					)}
				</div>

				{!editLayer && (
					<div className={styles.columnLabelContainer}>
						<div className={styles.columnLabel}> {t('en:SELL')}</div>
						<div className={styles.columnLabel}> {t('en:BUY')}</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default WatchlistHeader;
