import React, { FC, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AppContext from '../../../../../contexts/AppContext';
import DashboardContext from '../../../../../contexts/DashboardContext';

import tradingAccountStore from '../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';

import usePreferredFeedId from '../../../../../utils/hooks/usePreferredFeedId';
import useSelectedTradingAccount from '../../../../../utils/hooks/useSelectedTradingAccount';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';

import {
	DEFAULT_FEED_ID,
	MAX_INSTRUMENTS_ALLOWED_JP,
	MAX_INSTRUMENTS_ALLOWED,
	createNewWatchlist,
	MAX_WATCHLISTS_ALLOWED,
} from '../../../../../utils/functions/WatchlistUtils';

import Button from '../../../../../shared/Button';
import WtrPopup from '../../../../components/WtrPopup/WtrPopup';
import CreateWatchlistButton from '../../../Dashboard/Watchlist/CreateWatchlistButton';

import { MarketItem } from '../../../../../gateways/RfpGateway/rfp.types';
import { TWatchlistInstrument } from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import authStore from '../../../../../store/authStore';
import styles from '../../MarketsGrid/MarketsGrid.module.scss';
import StringUtils from '../../../../../utils/functions/StringUtils';

import NewWatchlistsList from './NewWatchlistsList';

interface AddToWatchlistProps {
	marketItem: MarketItem;
}

const AddToWatchlistWrapper: FC<AddToWatchlistProps> = ({ marketItem }): JSX.Element => {
	const { t } = useTranslation();
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const activeTradingAccount = useSelectedTradingAccount();
	const preferredFeedId = usePreferredFeedId(activeTradingAccount);

	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const isWatchlistNameExists = watchListStore.use.isWatchlistNameExists();
	const isInstrumentExistsInAnyWatchlist = watchListStore.use.isInstrumentExistsInAnyWatchlist();
	const allWatchlists = getWatchlists(tradingMode);
	const selectedWatchlist = getSelectedWatchlist(tradingMode);

	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);

	const isArabic = appContext.isArabic;
	const languageSettings = appContext.languageSettings;
	const selectedTradingAccountWatchlists = dashboardContext.watchlist;

	const currentWatchList = watchListStore.use.currentWatchList();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();

	const [openWatchlistMenu, setOpenWatchlistMenu] = useState<boolean>(false);
	const [createWatchList, setCreateWatchList] = useState<boolean>(false);
	const [typedValue, setTypedValue] = useState<string>('');
	const [duplicateName, setDuplicateName] = useState<boolean>(false);
	const [resizeText, setResizeText] = useState<boolean>(false);

	useEffect(() => {
		if (['zh-Hans', 'zh-Hant', 'ja', 'de'].includes(languageSettings)) {
			setResizeText(true);
		}
	}, [languageSettings, setResizeText]);

	// Popup position
	const popupPosition = useMemo(() => {
		return isArabic ? 'bottom left' : 'bottom right';
	}, [isArabic]);

	const instrumentExistsInWatchlist = useMemo(() => {
		if (!allWatchlists) {
			return false;
		}

		return isInstrumentExistsInAnyWatchlist(tradingMode, marketItem.code);
	}, [allWatchlists, marketItem]);

	const maxWatchlistLimit = useMemo(() => {
		if (!allWatchlists) {
			return false;
		}

		return allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
	}, [allWatchlists]);

	const handleOpenWatchlist = () => {
		if (allWatchlists && selectedWatchlist && allWatchlists.length === 1) {
			let instruments: TWatchlistInstrument[] = [];
			const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
			const isMaxedOut = selectedWatchlist.instruments.length >= maxInstruments;

			if (!instrumentExistsInWatchlist && !isMaxedOut) {
				const selectedInstrument = selectedWatchlist.instruments.map((instrument) => ({ _code: instrument }));
				instruments = [...selectedInstrument, { _code: marketItem.code }];
			} else {
				const selectedInstrument = selectedWatchlist.instruments.filter((instrument) => instrument !== marketItem.code);
				instruments = [...selectedInstrument.map((instrument) => ({ _code: instrument }))];
			}
			selectedTradingAccountWatchlists[0].instrument = instruments;
			dashboardContext.watchlist = selectedTradingAccountWatchlists;
			saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
		} else {
			setOpenWatchlistMenu((state) => !state);
			setCreateWatchList(false);
		}
	};

	const handleCreateWatchlist = useCallback(() => {
		if (!maxWatchlistLimit) {
			setCreateWatchList((state) => !state);
			setTypedValue('');
		}
	}, [maxWatchlistLimit, setCreateWatchList, setTypedValue]);

	const handleCreateWatchListClose = useCallback(() => {
		setCreateWatchList(false);
		setTypedValue('');
		setDuplicateName(false);
	}, [setCreateWatchList, setTypedValue, setDuplicateName]);

	const changeValue = useCallback(
		({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
			setTypedValue(value);
			duplicateName && setDuplicateName(false);
		},
		[setTypedValue, duplicateName, setDuplicateName]
	);

	const handleSaveWatchlist = () => {
		if (allWatchlists) {
			const addInstrument =
				marketItem && marketItem.code
					? dashboardContext.marketItems.filter((item: MarketItem) => item.code === marketItem.code)
					: [];

			const newWatchlistName = typedValue.trim();
			const feedId = StringUtils.isNullOrEmpty(preferredFeedId) ? DEFAULT_FEED_ID : preferredFeedId;
			const alreadyExists = isWatchlistNameExists(tradingMode, newWatchlistName);

			setDuplicateName(alreadyExists);

			if (newWatchlistName.length > 0 && !alreadyExists && allWatchlists) {
				const newWatchlistEntry = createNewWatchlist(newWatchlistName, feedId);
				newWatchlistEntry.instrument = [{ _code: addInstrument[0].code }];
				dashboardContext.watchlist.push(newWatchlistEntry);
				saveWatchlistToPreferences(dashboardContext.watchlist, isSpreadBettingAccount);
				setCreateWatchList(false);
			}
		}
	};

	const checkWatchlistValidation = (typedValue: string) => {
		if (!allWatchlists) {
			return true;
		}
		const maxWatchlistsReached = allWatchlists.length >= MAX_WATCHLISTS_ALLOWED;
		return (allWatchlists && maxWatchlistsReached) || typedValue.trim().length <= 0;
	};

	return (
		<div key={`watchListPopup${marketItem}`}>
			{openWatchlistMenu && (
				<WtrPopup
					open={openWatchlistMenu}
					className={styles.Popup}
					onClose={() => setOpenWatchlistMenu(false)}
					content={
						!createWatchList ? (
							<div className={styles.watchlistsListContainer}>
								<NewWatchlistsList
									instrumentExistsInWatchlist={instrumentExistsInWatchlist}
									marketItemCode={marketItem.code}
								/>

								<CreateWatchlistButton
									maxWatchlistsError={maxWatchlistLimit}
									handleCreateWatchlist={handleCreateWatchlist}
								/>
							</div>
						) : (
							<>
								<div className={styles.createWLContainer}>
									<div className={styles.createWatchlistItems}>{t('wtr:WATCHLIST_NAME')}</div>

									<div className={styles.inputContainer}>
										<div className={styles.inputTitle}>{t('wtr:WATCHLIST_NAME')}</div>
										<input
											type="text"
											className={duplicateName ? styles.errorInput : styles.costInput}
											maxLength={20}
											value={typedValue}
											onChange={changeValue}
											autoFocus
										/>
										<div className={styles.messageRow}>
											{duplicateName && <div className={styles.errorMessage}>{t('wtr:NAME_IN_USE')}</div>}
											<span className={styles.inputCount}>{typedValue.trim().length}/20</span>
										</div>
									</div>
									<div className={styles.popupFooter}>
										<Button
											variant="secondary"
											size="sm"
											label={t('en:CANCEL')}
											className={(resizeText && styles.resizedCancelBtn) || ''}
											onClick={handleCreateWatchListClose}
										/>
										<Button
											variant="primary"
											size="sm"
											label={t('en:CREATE')}
											className={(resizeText && styles.resizedSaveBtn) || ''}
											onClick={() => handleSaveWatchlist()}
											disabled={checkWatchlistValidation(typedValue)}
										/>
									</div>
								</div>
							</>
						)
					}
					on={['click']}
					pinned
					position={popupPosition}
					trigger={
						<div>
							<OverlayTrigger
								key={`marketGridWatchList${marketItem.code}`}
								delay={{ show: 750, hide: 0 }}
								placement={isArabic ? 'right' : 'left'}
								overlay={
									<Tooltip className="my-tooltip" id={`marketGridWatchList${marketItem.code}`}>
										<div>{t('wtr:ADD_TO_WATCHLIST')}</div>
									</Tooltip>
								}
							>
								<div>
									<FontAwesomeIcon
										id={instrumentExistsInWatchlist ? 'existsIcon' : 'addIcon'}
										icon={instrumentExistsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
										className={cn({
											[styles.existsInWatchlistIcon]: instrumentExistsInWatchlist,
											[styles.addToWatchlistIcon]: !instrumentExistsInWatchlist,
										})}
										onClick={handleOpenWatchlist}
									/>
								</div>
							</OverlayTrigger>
						</div>
					}
				/>
			)}
			{!openWatchlistMenu && (
				<FontAwesomeIcon
					id={instrumentExistsInWatchlist ? 'existsIcon' : 'addIcon'}
					icon={instrumentExistsInWatchlist ? ['fas', 'check-circle'] : ['far', 'plus-circle']}
					className={cn({
						[styles.existsInWatchlistIcon]: instrumentExistsInWatchlist,
						[styles.addToWatchlistIcon]: !instrumentExistsInWatchlist,
					})}
					onClick={handleOpenWatchlist}
				/>
			)}
		</div>
	);
};

export default AddToWatchlistWrapper;
