import React, { FC, useContext } from 'react';

import { useTranslation } from 'react-i18next';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { default as DashboardContext } from '../../../../../contexts/DashboardContext';
import {
	instrumentExistsInDynamicWatchlists,
	MAX_INSTRUMENTS_ALLOWED,
	MAX_INSTRUMENTS_ALLOWED_JP,
} from '../../../../../utils/functions/WatchlistUtils';

import AddToWatchlist from '../AddToWatchlist';

import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import tradingAccountStore from '../../../../../store/tradingAccountStore';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import authStore from '../../../../../store/authStore';
import AppContext from '../../../../../contexts/AppContext';

import styles from './WatchlistsList.module.scss';

interface WatchlistsListProps {
	hasLeftIcon?: boolean;
}

const WatchlistsList: FC<WatchlistsListProps> = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const getTranslatedWLName = useGetTranslatedWLName();
	const { t } = useTranslation();
	const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const allWatchlists = getWatchlists(tradingMode);
	const selectedWatchlist = getSelectedWatchlist(tradingMode);

	return (
		<div>
			<div className={styles.titleContainer}>
				<div className={styles.titleWatchlist}>{t('wtr:SELECT_WATCHLIST')}</div>
			</div>
			<div className={styles.watchlistContainer}>
				{allWatchlists &&
					selectedWatchlist &&
					allWatchlists.map((watchlistData) => {
						const watchlistName = watchlistData.name;
						if (
							instrumentExistsInDynamicWatchlists(
								watchlistName,
								dashboardContext.defaultWatchLists,
								isSpreadBettingAccount
							)
						) {
							return null;
						}
						const maxInstruments = appContext.isJapanAccount ? MAX_INSTRUMENTS_ALLOWED_JP : MAX_INSTRUMENTS_ALLOWED;
						const maxReached = watchlistData.instruments.length >= maxInstruments;
						const isInstrumentExistInWatchlist = dashboardContext.selectedInstrument
							? watchlistData.instruments.includes(dashboardContext.selectedInstrument.code)
							: false;
						return (
							<div
								className={
									maxReached && dashboardContext.selectedInstrument && allWatchlists && !isInstrumentExistInWatchlist
										? styles.maxItemContainer
										: styles.itemContainer
								}
							>
								<div className={styles.watchlistItem}>
									<OverlayTrigger
										delay={{ show: 750, hide: 0 }}
										key={watchlistName}
										placement="bottom-end"
										overlay={<Tooltip id={watchlistName}>{getTranslatedWLName(watchlistName)}</Tooltip>}
									>
										<>
											<span style={{ cursor: 'default' }}>{getTranslatedWLName(watchlistName)}</span>
											{maxReached && <div className={styles.maxErrorMessage}>{t('wtr:MAX_INSTRUMENT_ERROR')}</div>}
										</>
									</OverlayTrigger>
								</div>

								<AddToWatchlist watchlistName={watchlistName} maxReached={maxReached} action={'ADD'} />
							</div>
						);
					})}
			</div>
		</div>
	);
};

export default WatchlistsList;
