import { useContext } from 'react';

import DashboardContext from '../../contexts/DashboardContext';
import tradingAccountStore from '../../store/tradingAccountStore';
import {
	convertTWatchlistsToWatchListData,
	DEFAULT_FEED_ID,
	dynamicWatchlistPreference,
} from '../functions/WatchlistUtils';
import { TWatchlists } from '../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';
import AppContext from '../../contexts/AppContext';
import { TradingAccountType } from '../functions/enums';
import watchListStore from '../../store/WatchListStore/watchListStore';

export const useDynamicWatchLists = () => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);

	const setCurrentWatchList = watchListStore.use.setCurrentWatchList();
	const currentWatchList = watchListStore.use.currentWatchList();
	const setWatchlistsForAllTradingModes = watchListStore.use.setWatchlistsForAllTradingModes();
	const isSpreadBettingAcc = tradingAccountStore.use.isSpreadBetting();

	const isChildWindow = appContext.isChildWindow ?? false;

	return (cfdWatchlists: TWatchlists[], isFundedTrader: boolean, comingFromPreferences?: boolean) => {
		let dynamicWatchlists: any = [];
		if (dashboardContext.defaultWatchLists.length > 0 && !isFundedTrader) {
			for (let watchList of dashboardContext.defaultWatchLists) {
				const currentDynamicWatchlists = watchList?.items.map((item) => {
					return dynamicWatchlistPreference(DEFAULT_FEED_ID, item.title, item.codes_csv, item.order);
				});
				dynamicWatchlists = [...dynamicWatchlists, ...currentDynamicWatchlists];
			}
		}

		if (dynamicWatchlists.length > 0 && !isSpreadBettingAcc && !isFundedTrader) {
			dashboardContext.watchlist = [...dynamicWatchlists, ...cfdWatchlists];
		} else {
			dashboardContext.watchlist = [...cfdWatchlists];
		}

		if (isFundedTrader && dynamicWatchlists.length) {
			dashboardContext.watchlist = dashboardContext.watchlist.slice(dynamicWatchlists.length);
		}

		// Some old logic
		// if (dynamicWatchlists.length > 0 && !appContext.isJapanAccount && !isChildWindow) {
		// 	setCurrentWatchList(dashboardContext.watchlist[0]?._name);
		// }

		let watchListName: string | undefined = currentWatchList;
		if (appContext.isJapanAccount && !isChildWindow) {
			const liveCFDWatchlist = appContext.userPreferences?.user_prefs?.watchlists?.account.find((account) => {
				return account && account._type.toUpperCase() === TradingAccountType.LIVE && !account.accountType;
			});

			const watchlistNames = [
				currentWatchList,
				liveCFDWatchlist?._lastUsedWatchlist || '',
				dashboardContext.watchlist[0]?._name,
			];
			watchListName = watchlistNames.find((name) =>
				dashboardContext.watchlist.find((watchList) => watchList._name === name)
			);

			setCurrentWatchList(watchListName);
		} else if (dashboardContext.watchlist.length > 0) {
			watchListName = [currentWatchList, dashboardContext.watchlist[0]._name].find((name) =>
				dashboardContext.watchlist.some((watchList) => watchList._name === name)
			);
		}
		setWatchlistsForAllTradingModes(convertTWatchlistsToWatchListData(dashboardContext.watchlist));
		if (!comingFromPreferences) {
			setCurrentWatchList(watchListName);
		}
	};
};
