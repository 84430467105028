import React, { FC, useContext } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';

import grabIndicator from '../../../../../images/grab-indicator.svg';
import isWindowStyles from '../../../../../utils/functions/isWindowStyles';
import useGetTranslatedWLName from '../../../../../utils/hooks/useGetTranslatedWLName';
import WatchlistHeader from '../WatchlistHeader/WatchlistHeader';
import DashboardContext from '../../../../../contexts/DashboardContext';
import watchListStore from '../../../../../store/WatchListStore/watchListStore';
import useShortTranslation from '../../../../../utils/hooks/useShortTranslation';
import authStore from '../../../../../store/authStore';

import AppContext from '../../../../../contexts/AppContext';
import RfpGatewayContext from '../../../../../contexts/RfpGatewayContext';
import { DEFAULT_FEED_ID } from '../../../../../utils/functions/WatchlistUtils';
import useSaveWatchlistToPreferences from '../../../../../utils/hooks/watchlist/useSaveWatchlistToPreferences';
import { TWatchlistInstrument } from '../../../../../gateways/UserPreferencesGateway/UserPreferencesGateway.types';

import styles from './WatchlistEdit.module.scss';

interface WatchlistEditProps {
	removeAll: boolean;
	window1?: boolean;
	openRemoveModal: boolean;
	removeItem: any[];
	editLayer: boolean;
	setOpenMove: (open: boolean) => void;
	handleSelectAllWatchListElements: (event: any) => void;
	handleWatchListSelection: (event: any, instrument: string) => void;
	resetEditLayer: () => void;
	setOpenRemoveModal: (open: boolean) => void;
}

const WatchlistEdit: FC<WatchlistEditProps> = ({
	removeAll,
	window1,
	openRemoveModal,
	removeItem,
	editLayer,
	setOpenMove,
	handleSelectAllWatchListElements,
	handleWatchListSelection,
	resetEditLayer,
	setOpenRemoveModal,
}) => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const currentWatchList = watchListStore.use.currentWatchList();
	const storeWatchlists = watchListStore.use.watchlists();
	const saveWatchlistToPreferences = useSaveWatchlistToPreferences();
	const getTranslatedWLName = useGetTranslatedWLName();
	const rfpGatewayContext = useContext(RfpGatewayContext);
	const { t } = useTranslation();
	const tt = useShortTranslation('en:');
	const watchlistsCount = watchListStore.use.watchlistsCount();
	const watchlistInstrumentCount = watchListStore.use.watchlistInstrumentCount();
	const getWatchlistInstruments = watchListStore.use.getWatchlistInstruments();
	const reorderWatchlistInstruments = watchListStore.use.reorderWatchlistInstruments();
	const getSelectedWatchlist = watchListStore.use.getSelectedWatchlist();
	const tradingMode = authStore.use.tradingMode();
	const theme = appContext.appTheme;
	const allWatchlistsCount = watchlistsCount(tradingMode);

	const onDragEnd = (result: any) => {
		if (!result.destination) {
			return;
		}
		if (storeWatchlists && currentWatchList) {
			reorderWatchlistInstruments(tradingMode, currentWatchList, result.source.index, result.destination.index);

			const selectedWatchlist = getSelectedWatchlist(tradingMode);
			if (selectedWatchlist) {
				const items = selectedWatchlist.instruments.map((instrument) => {
					return { _code: instrument } as TWatchlistInstrument;
				});

				const updatePreferenceObject = dashboardContext.watchlist.find(
					(watchlist) => watchlist._name === currentWatchList
				);
				if (updatePreferenceObject) {
					updatePreferenceObject.instrument = items;
					saveWatchlistToPreferences(dashboardContext.watchlist);
				}
			}
		}
	};

	const editOptionContainer = (
		<>
			<div id="action-row" className={styles.actionRow}>
				<div className={styles.editOptionContainer}>
					<label
						className={cn(
							styles.selectAll,
							watchlistInstrumentCount(tradingMode, currentWatchList) === 0 ? styles.disableBtn : '',
							styles.editOptionCheckboxContainer
						)}
					>
						<input
							onChange={(event) => handleSelectAllWatchListElements(event)}
							type="checkbox"
							id={`instrumentAllChecked`}
							checked={removeAll}
						></input>
						<span className={styles.checkmark}></span>
						<span className={styles.textAll}>{t('wtr:SELECT_ALL')}</span>
					</label>
				</div>
				<div className={styles.editOptionContainer}>
					<label
						className={cn(styles.move, removeItem.length === 0 || allWatchlistsCount <= 1 ? styles.disableBtn : '')}
						onClick={() => setOpenMove(true)}
					>
						<FontAwesomeIcon icon={['fas', 'truck']} className={styles.editOptionIcons} />
						<span className={styles.editBarText}>{tt('WATCHLIST_MOVE_BTN_TITLE')}</span>
					</label>
				</div>
			</div>
		</>
	);

	return (
		<>
			<div className={styles.container}>
				<div className={isWindowStyles(styles.editHeader, styles.windowEditHeader, window1)}>
					<div className={isWindowStyles(styles.editIcon, styles.windowHeader, window1)} onClick={resetEditLayer}>
						<FontAwesomeIcon icon={['fas', 'chevron-left']} size="1x" className={styles.icon} />
					</div>
					<div className={cn(styles.openModalTitle, styles[`${theme}Title`])}>
						{' '}
						{getTranslatedWLName(currentWatchList)}{' '}
					</div>
					<div className={styles.removeButton}>
						<label
							className={cn(styles.remove, removeItem.length === 0 ? styles.disableBtn : '')}
							onClick={() => setOpenRemoveModal(!openRemoveModal)}
						>
							<FontAwesomeIcon icon={['fas', 'trash-alt']} className={styles.removeItemsIcon} />
						</label>
					</div>
				</div>

				{currentWatchList && watchlistsCount(tradingMode) > 0 && (
					<div className={isWindowStyles(styles.WatchListCategories, styles.windowWatchListCategories, window1)}>
						{watchlistInstrumentCount(tradingMode, currentWatchList) > 0 && (
							<div
								id="edit-scroll"
								className={isWindowStyles(styles.WatchListCategoriesChildEdit, styles.windowEditDragContainer, window1)}
							>
								<div className={styles.instrumentBox}>
									<WatchlistHeader window={window1} editLayer={editLayer} />
								</div>
								<DragDropContext onDragEnd={onDragEnd}>
									<Droppable droppableId="droppable">
										{(provided) => (
											<div
												id="edit-droppable"
												className={cn(!window1 ? styles.MarketTickerContainer : styles.windowMarketTickerContainerEdit)}
												{...provided.droppableProps}
												ref={provided.innerRef}
											>
												{getWatchlistInstruments(tradingMode, currentWatchList).map((instrument, i) => {
													const mktItem = rfpGatewayContext?.getMarketItem(instrument, DEFAULT_FEED_ID);
													return (
														mktItem && (
															<Draggable key={mktItem.code} draggableId={mktItem.code} index={i}>
																{(provided) => (
																	<div
																		className={cn(
																			isWindowStyles(styles.editContainer, styles.windowEditContainer, window1)
																		)}
																		data-testid="instrumentList"
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																	>
																		<label
																			className={cn(
																				styles.checkboxContainer,
																				isWindowStyles(styles.TickerInfo, styles.windowTickerInfo, window1)
																			)}
																		>
																			<input
																				onChange={(event) => handleWatchListSelection(event, instrument)}
																				type="checkbox"
																				id={`instrumentChecked_${i}`}
																			></input>
																			<span className={styles.checkmark}></span>
																			<div className={styles.tickerContainer} data-testid="chart">
																				<div className={cn(styles.Symbol, styles[`${theme}Symbol`])}>
																					{mktItem.displayName}
																				</div>
																				<div
																					className={cn(styles.SymbolDescription, styles[`${theme}SymbolDescription`])}
																				>
																					{mktItem.fullName}
																				</div>
																			</div>
																		</label>
																		<div className={styles.grabIndicatorContainer}>
																			<img alt="grabIndicator" src={grabIndicator} className={styles.grabIndicator} />
																		</div>
																	</div>
																)}
															</Draggable>
														)
													);
												})}
												{provided.placeholder}
											</div>
										)}
									</Droppable>
								</DragDropContext>
								{editOptionContainer}
							</div>
						)}
					</div>
				)}
			</div>
		</>
	);
};

export default WatchlistEdit;
