import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import cn from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

import { useTranslation } from 'react-i18next';

import useShortTranslation from '../../../../../../../utils/hooks/useShortTranslation';
import InstrumentHeader from '../../../../../../components/InstrumentSummary/InstrumentHeader';
import {
	MarketItem,
	PriceQuote,
	TradingPosition,
	TradingPositionState,
} from '../../../../../../../gateways/RfpGateway/rfp.types';

import { default as AppContext } from '../../../../../../../contexts/AppContext';
import { default as DashboardContext } from '../../../../../../../contexts/DashboardContext';
import { default as RfpGatewayContext } from '../../../../../../../contexts/RfpGatewayContext';
import { default as usePromiseFactory } from '../../../../../../../utils/hooks/usePromiseFactory';
import { default as useForceRerender } from '../../../../../../../utils/hooks/useForceRerender';
import { default as useObservable } from '../../../../../../../utils/hooks/useObservable';
import { formatNumberWithCommas } from '../../../../Watchlist/Instrument/formattedQuoteNumber';
import { MarginAccountType, QUANTITY_TYPE, TradingPositionSide } from '../../../../../../../utils/functions/enums';
import CloseButton from '../../../../../../components/CloseButton/CloseButton';
import StringUtils from '../../../../../../../utils/functions/StringUtils';
import { TradersGymContext, TradersGymContextType } from '../../../../../../../pages/TradersGym/TradersGymContext';
import {
	calcPercentChange,
	convertAmountToLot,
	convertToBaseCurrency,
	formatNumberAsMoney,
	getAdditionalSubscriptionPairs,
	getEffectiveSize,
	getMarginCalculationType,
	getMarginImpact,
} from '../../../../../../../utils/functions/calculations';
import { getGeneralFormatDate } from '../../../../../../../utils/functions/subscriptionUtils';

import accountStatusStore, { StatusStore } from '../../../../../../../store/accountStatusStore';

import useSelectedTradingAccount from '../../../../../../../utils/hooks/useSelectedTradingAccount';

import tradingAccountStore from '../../../../../../../store/tradingAccountStore';
import orderStore from '../../../../../../../store/OrderStore/orderStore';
import MarginRequirementsContext from '../../../../../../../contexts/MarginRequirementsContext';

import ModalOrderInformation from './ModalOrderInformation';
import InfoElement from './InfoElement';

import styles from './OrderInformation.module.scss';
import tradingViewStore from '../../../../../../../store/tradingViewStore';

interface OrderInformationProps {
	redirectFromDashboard: boolean;
}

const OrderInformation = React.memo(
	({ redirectFromDashboard }: OrderInformationProps) => {
		const dashboardContext = useContext(DashboardContext);
		const rfpGatewayContext = useContext(RfpGatewayContext);
		const appContext = useContext(AppContext);
		const promiseFactory = usePromiseFactory();
		const forceRerender = useForceRerender();
		const languageSettings = appContext.languageSettings;
		const setDockStylesTicket = tradingViewStore.use.setDockStylesTicket();

		const subIdRef = useRef<string | undefined>(undefined);

		const gymContext = useContext(TradersGymContext) as TradersGymContextType;
		const isChildWindow = appContext.isChildWindow;
		const { tradersGymContext } = gymContext;

		const quantityType = dashboardContext.quantityType;
		const selectedPosition = dashboardContext.selectedPosition;
		const detailedInformation = dashboardContext.detailedInformation;
		const selectedMarketItem = dashboardContext.selectedInstrument;
		const showOrderInformation = dashboardContext.showOrderInformation;
		const activeState = dashboardContext.toggleAccordionMenu;
		const marginRequirementsContext = useContext(MarginRequirementsContext);

		const tradingPositions = tradersGymContext.isActive
			? tradersGymContext.gymTradingAccount?.activePositions ?? []
			: dashboardContext.getTradingPositions();

		const selectedTradingAccount = tradersGymContext.isActive
			? tradersGymContext.gymTradingAccount
			: useSelectedTradingAccount();
		const isSpreadBettingAccount = tradingAccountStore.use.isSpreadBetting();
		const [additionalSubscriptionPairValue, setAdditionalSubscriptionPairValue] = useState<PriceQuote | undefined>(
			undefined
		);

		// const showTradeButton = tradingViewStore.use.showTradeButton();
		// const setShowTradeButton = tradingViewStore.use.setShowTradeButton();

		useEffect(() => {
			setDockStylesTicket(redirectFromDashboard);
		}, []);

		useObservable(appContext.getPropertyChangeStream('appTheme'), async () => {
			await promiseFactory.throttle('appContext.propertyChanged', 100);
			forceRerender();
		});

		useObservable(
			dashboardContext.getPropertyChangeStream(
				'quantityType',
				'selectedPosition',
				'selectedInstrument',
				'marketItems',
				'marketItemsInfo',
				'detailedInformation',
				'showOrderInformation',
				'toggleAccordionMenu',
				'tradingAccount',
				'tradingPositions',
				'accountValues'
			),
			async () => {
				await promiseFactory.throttle('dashboardContext.propertyChanged', 100);
				forceRerender();
			}
		);

		const [currentPriceQuote, setCurrentPriceQuote] = useState<PriceQuote | undefined>(undefined);

		const tt = useShortTranslation('en:');
		const { t } = useTranslation();

		const [pipPL, setPipPL] = useState<number | any>(0);
		const [percentageChange, setPercentageChange] = useState<string | any>('0%');
		const [red, setRed] = useState<boolean>(false);
		const [redPip, setRedPip] = useState<boolean>(false);
		const [pendingAmountLot, setPendingAmountLot] = useState(0);
		const [matchedAmountLot, setMatchedAmountLot] = useState(0);
		const [matchedPendingOrder, setMatchedPendingOrder] = useState<any>([]);
		const [matchedOpenPosition, setMatchedOpenPosition] = useState<any>([]);
		const [active, setActive] = useState<string | undefined>(activeState);

		const [usedMargin, setUsedMargin] = useState(0);
		const [comment, setComment] = useState<string>();
		const current = orderStore.use.current();

		const tradeButtons = accountStatusStore((store: StatusStore) => (store.permissions || {}).tradeButtons);
		const setShowModal = accountStatusStore((store: StatusStore) => store.setShowModal);

		//If Accordion is updated via charts or other external component, open the appropriate menu.
		useEffect(() => {
			if (activeState === '0') {
				setActive('0');
			} else if (activeState === '1') {
				setActive('1');
			} else if (activeState === '2') {
				setActive('2');
			}
		}, [activeState]);

		//State to manage which accordion is open
		const openMarketInfo = () => {
			if (active === '0') {
				setActive(undefined);
			} else {
				setActive('0');
			}
		};

		const openPositionInfo = () => {
			if (active === '1') {
				setActive(undefined);
			} else {
				setActive('1');
			}
		};

		const openOrderInfo = () => {
			if (active === '2') {
				setActive(undefined);
			} else {
				setActive('2');
			}
		};

		const calculateUsedMargin = () => {
			let instrumentMarginReq = 0;
			if (selectedTradingAccount) {
				const openPositions = [];
				for (const positionId in selectedTradingAccount.activePositions) {
					const position = selectedTradingAccount.activePositions[positionId];
					if (position.state === TradingPositionState.open) {
						openPositions.push(position);
					}
				}

				if (matchedOpenPosition.length > 0) {
					const pos = matchedOpenPosition[0];
					const marketItem: MarketItem | undefined = pos.marketItem;
					const marginIdentifier = getMarginCalculationType(selectedTradingAccount);
					const size = getEffectiveSize(marginIdentifier, pos.code, +selectedTradingAccount.id, matchedOpenPosition);
					const instrument = selectedTradingAccount.tradingInstruments?.instruments[pos.code];

					if (instrument && instrument.marginReq) {
						instrumentMarginReq = instrument.marginReq;
					}
					if (marketItem && currentPriceQuote) {
						const impact = getMarginImpact(instrumentMarginReq, size.val, currentPriceQuote.a);
						const convertedToBase = convertToBaseCurrency(
							selectedTradingAccount,
							impact,
							currentPriceQuote.a,
							marketItem.qCcy,
							marketItem.feedId,
							marketItem.code,
							pos.side === 'BUY',
							rfpGatewayContext
						);

						const hedged = convertedToBase !== null ? convertedToBase : impact;
						setUsedMargin(selectedTradingAccount.isJapanAccount ? Math.ceil(hedged) : hedged);
					}
				}
			}
		};

		//Update the state of menus to reflect changes in grid or watchlist
		useEffect(() => {
			orderMenuState();
			positionMenuState();
		}, [
			dashboardContext.selectedInstrument,
			dashboardContext.selectedPosition,
			tradingPositions,
			matchedAmountLot,
			pendingAmountLot,
			detailedInformation,
		]);

		useEffect(() => {
			calculateUsedMargin();
			updateCommentValue();
		}, [matchedOpenPosition]);

		useEffect(() => {
			const matchedPosition = [...matchedOpenPosition, ...matchedPendingOrder];
			if (matchedPosition.length > 0) {
				let percentChange = calcPercentChange(matchedPosition[0]);
				if (isNaN(percentChange)) {
					setRed(false);
					setPercentageChange(`N/A`);
				} else {
					percentChange < 0 ? setRed(true) : setRed(false);
					setPercentageChange(`${formatNumberWithCommas(percentChange, 2, languageSettings)}%`);
				}
			}

			if (matchedOpenPosition.length > 0) {
				if (matchedOpenPosition.length === 1 && selectedMarketItem) {
					const position = matchedOpenPosition[0] as TradingPosition;
					if (position) {
						const pipPlVal = position.pips ?? NaN;
						setPipPL(formatNumberWithCommas(pipPlVal, 2, languageSettings));
						pipPlVal < 0 ? setRedPip(true) : setRedPip(false);
					}
				}
			}
		}, [currentPriceQuote, matchedOpenPosition, matchedPendingOrder, additionalSubscriptionPairValue]);

		const addAdditionalSubPairsCodes = (subCodes: string[]): void => {
			if (selectedTradingAccount && selectedMarketItem) {
				let pairs = getAdditionalSubscriptionPairs(selectedTradingAccount, selectedMarketItem, rfpGatewayContext);
				if (pairs) {
					pairs.forEach((pair) => {
						subCodes.push(pair);
					});
				}
			}
		};

		useEffect(() => {
			if (rfpGatewayContext && selectedMarketItem) {
				// unsubscribe previous quotes
				if (subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
				}

				const feedId = selectedMarketItem.feedId;
				const code = selectedMarketItem.code;
				let subCodes: string[] = [code];

				addAdditionalSubPairsCodes(subCodes);

				// subscribe for price quote
				subIdRef.current = rfpGatewayContext.subscribePriceQuote(feedId, subCodes, (priceQuote) => {
					if (!tradersGymContext.isActive) {
						if (priceQuote.c === selectedMarketItem.code) {
							setCurrentPriceQuote(priceQuote);
						} else {
							setAdditionalSubscriptionPairValue(priceQuote);
						}
					}
				});
			}

			// unsubscribe price quote on unmount
			return () => {
				if (rfpGatewayContext && subIdRef.current) {
					rfpGatewayContext.unsubscribePriceQuote(subIdRef.current);
					subIdRef.current = undefined;
				}
			};
		}, [
			tradingPositions,
			selectedPosition,
			selectedMarketItem,
			rfpGatewayContext,
			matchedOpenPosition,
			matchedPendingOrder,
		]);

		useEffect(() => {
			if (tradersGymContext.priceQuote) {
				setCurrentPriceQuote(tradersGymContext.priceQuote);
			}
		}, [tradersGymContext.priceQuote]);

		useEffect(() => {
			if (matchedOpenPosition.length > 0) {
				const pos = matchedOpenPosition[0];
				let instrument = selectedTradingAccount?.tradingInstruments?.instruments[pos.code];
				const instrumentRndLot = (instrument && instrument.rndLot) || 0;
				const amountLot = quantityType === QUANTITY_TYPE.LOTS ? convertAmountToLot(pos.qty, instrumentRndLot) : pos.qty;
				setMatchedAmountLot(amountLot);
			}
			if (matchedPendingOrder.length > 0) {
				const pos = matchedPendingOrder[0];
				let instrument = selectedTradingAccount?.tradingInstruments?.instruments[pos.code];
				const instrumentRndLot = (instrument && instrument.rndLot) || 0;
				const amountLot = quantityType === QUANTITY_TYPE.LOTS ? convertAmountToLot(pos.qty, instrumentRndLot) : pos.qty;
				setPendingAmountLot(amountLot);
			}
		}, [quantityType, matchedPendingOrder, matchedOpenPosition]);

		useEffect(() => {
			//Isolate the pending orders and open positions
			if (
				(selectedMarketItem && Object.keys(selectedMarketItem).length > 0) ||
				(Array.isArray(selectedPosition) && selectedPosition.length > 0)
			) {
				const pendingOrders = tradingPositions.filter(
					(position: TradingPosition) =>
						position.state === TradingPositionState.pending && position.aId === selectedTradingAccount?.id
				);
				const openPositions = tradingPositions.filter(
					(position: TradingPosition) =>
						position.state === TradingPositionState.open && position.aId === selectedTradingAccount?.id
				);
				const code = selectedMarketItem
					? selectedMarketItem.code ?? selectedPosition[0].code
					: selectedPosition[0].code;

				let openedInstrument = openPositions?.filter((position: TradingPosition) => code === position.code);
				let pendingInstrument = pendingOrders?.filter((position: TradingPosition) => code === position.code);

				if (
					openedInstrument?.length > 1 &&
					selectedPosition?.length === 1 &&
					selectedPosition[0].state === TradingPositionState.open &&
					selectedMarketItem &&
					selectedPosition[0].code === selectedMarketItem.code
				) {
					openedInstrument = openedInstrument.filter(
						(instrument: TradingPosition) => instrument.posId === selectedPosition[0].posId
					);
				} else if (
					pendingInstrument?.length > 1 &&
					selectedPosition?.length === 1 &&
					selectedPosition[0].state === TradingPositionState.pending &&
					selectedMarketItem &&
					selectedPosition[0].code === selectedMarketItem.code
				) {
					pendingInstrument = pendingInstrument.filter(
						(instrument: TradingPosition) => instrument.posId === selectedPosition[0].posId
					);
				}
				if (openedInstrument.length > 0) {
					setMatchedOpenPosition(openedInstrument);
				} else {
					setMatchedOpenPosition([]);
				}
				if (pendingInstrument.length > 0) {
					setMatchedPendingOrder(pendingInstrument);
				} else {
					setMatchedPendingOrder([]);
				}
			} else {
				setMatchedOpenPosition([]);
				setMatchedPendingOrder([]);
			}
		}, [selectedPosition, selectedMarketItem, tradingPositions, selectedTradingAccount]);

		const formatValueAsMoney = (value: number) => {
			return selectedTradingAccount
				? formatNumberAsMoney(
						selectedTradingAccount.baseCurrency,
						!isNaN(value) ? value : 0,
						languageSettings,
						selectedTradingAccount.isJapanAccount
				  )
				: 0;
		};

		const commission = useMemo(
			() => formatValueAsMoney(matchedOpenPosition[0]?.comm ?? 0),
			[selectedTradingAccount, matchedOpenPosition, rfpGatewayContext]
		);

		const swap = useMemo(
			() => formatValueAsMoney(matchedOpenPosition[0]?.swap ?? 0),
			[selectedTradingAccount, matchedOpenPosition, rfpGatewayContext]
		);

		const dividend = useMemo(
			() => formatValueAsMoney(matchedOpenPosition[0]?.dividend ?? 0),
			[selectedTradingAccount, matchedOpenPosition, rfpGatewayContext]
		);

		const updateCommentValue = () => {
			let commandValue = matchedOpenPosition[0]?.comment;
			if (StringUtils.isNullOrEmpty(commandValue)) {
				commandValue = t('wtr:NA');
			}
			setComment(commandValue);
		};

		//Display state of position menu depending on if position(s) are selected
		const positionMenuState = () => {
			if (matchedOpenPosition.length > 1) {
				return (
					<>
						<div className={styles.positionMsg}>
							{matchedOpenPosition.length}{' '}
							{t('wtr:OPEN_CODE_ORDERS', { code: matchedOpenPosition[0].marketItem.displayName })}
							{!marginRequirementsContext?.doesRulesApply && !marginRequirementsContext?.marginRequirementsToApply ? (
								<div className={styles.marginUsedMsg}>
									<span>{t('wtr:MARGIN_USED')}</span>
									<span>{`${formatValueAsMoney(usedMargin)}`}</span>
								</div>
							) : null}
						</div>
					</>
				);
			} else if (matchedOpenPosition.length === 1) {
				const pos: TradingPosition = matchedOpenPosition[0];
				const decPrec = pos.marketItem?.decPrec ?? 2;

				const onDate = appContext.isJapanAccount
					? getGeneralFormatDate(pos.oT, false, true)
					: moment(pos.oT).format('D/MMM/YYYY').toLocaleUpperCase();

				const openPrice =
					appContext.isJapanAccount || !(pos.openAskPrice && pos.openBidPrice)
						? `${formatNumberWithCommas(pos.prc ?? 0, decPrec, languageSettings)}`
						: `${formatNumberWithCommas(pos.prc ?? 0, decPrec, languageSettings)} (${formatNumberWithCommas(
								pos.openBidPrice ?? 0,
								decPrec,
								languageSettings
						  )}/${formatNumberWithCommas(pos.openAskPrice ?? 0, decPrec, languageSettings)})`;
				return (
					<div className={styles.elementContainer}>
						<InfoElement title={t('wtr:WTR_ID')} value={pos.posId} />
						<InfoElement title={tt('SIDE')} value={tt(`${pos.side}`).toUpperCase()} />
						<InfoElement
							title={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
							value={formatNumberWithCommas(matchedAmountLot, 2, languageSettings)}
						/>
						<InfoElement title={t('wtr:ON_DATE')} value={onDate} valueClassName={styles.date} />
						<InfoElement title={tt('TIME')} value={moment(pos.oT).format('HH:mm:ss').toLocaleUpperCase()} />
						<InfoElement title={tt('OPEN_PRICE')} value={openPrice} />
						<InfoElement title={t('wtr:PER_CHANGE')} value={percentageChange} setRed={red} id="change" />
						<InfoElement
							title={tt('TAKE_PROFIT')}
							value={pos.tp && !isNaN(pos.tp) ? formatNumberWithCommas(pos.tp, decPrec, languageSettings) : t('wtr:NA')}
						/>
						{pos?.trailingStop ? (
							<InfoElement
								title={tt('TRAILING_INFORMATION')}
								value={
									pos.sl && !isNaN(pos.sl) ? formatNumberWithCommas(pos.sl, decPrec, languageSettings) : t('wtr:NA')
								}
							/>
						) : (
							<InfoElement
								title={tt('STOP_LOSS')}
								value={
									pos.sl && !isNaN(pos.sl) ? formatNumberWithCommas(pos.sl, decPrec, languageSettings) : t('wtr:NA')
								}
							/>
						)}

						<InfoElement
							title={t(`wtr:WTR_PL_IN_${isSpreadBettingAccount ? 'POINTS' : 'PIPS'}`)}
							setRedPip={redPip}
							value={pipPL}
							id="PL in Pips"
						/>
						{!appContext.isJapanAccount && <InfoElement title={t('wtr:WTR_DIVIDEND')} value={dividend} />}
						<InfoElement title={tt('SWAP')} value={swap} />
						{!appContext.isJapanAccount && <InfoElement title={tt('COMMISSION')} value={commission} />}
						<InfoElement title={tt('POSITION_COMMENT')} value={comment} />
					</div>
				);
			} else if (matchedOpenPosition.length === 0) {
				return (
					<div className={styles.emptyContainer}>
						<FontAwesomeIcon className={styles.icon} icon={['fal', 'chart-pie']} />
						<div className={styles.chartMessage}>{t('wtr:NO_OPEN_POSITION')}</div>
					</div>
				);
			}
		};

		//Display state of order menu depending on if position(s) are selected
		const orderMenuState = () => {
			if (matchedPendingOrder.length === 0) {
				return (
					<div className={styles.emptyContainer}>
						<FontAwesomeIcon className={styles.icon} icon={['fal', 'tasks']} />
						<div className={styles.chartMessage}>{t('wtr:NO_PENDING_ORDER')}</div>
					</div>
				);
			}
			//Check if there's any matched pending orders to be displayed
			if (matchedPendingOrder.length === 1) {
				const pos = matchedPendingOrder[0];
				const decPrec = pos.marketItem?.decPrec ?? 2;
				const createdOnDate = appContext.isJapanAccount
					? getGeneralFormatDate(pos.oT, false, true)
					: moment(pos.oT).format('D/MMM/YYYY').toLocaleUpperCase();
				let expiryDate;
				if (pos.eT === 0) {
					expiryDate = t('wtr:GTC');
				} else {
					expiryDate = appContext.isJapanAccount
						? getGeneralFormatDate(pos.eT, false, true)
						: moment(pos.eT).format('D/MMM/YYYY').toUpperCase();
				}

				return (
					<div className={styles.elementContainer}>
						<InfoElement title={t('wtr:WTR_ID')} value={pos.posId} />
						<InfoElement title={tt('SIDE')} value={tt(`${pos.side}`).toUpperCase()} />
						<InfoElement title={tt('TYPE')} value={tt(`${pos.type === 'LIMIT' ? 'TYPE_LIMIT' : 'TYPE_STOP'}`)} />
						<InfoElement
							title={tt(isSpreadBettingAccount ? 'POUND_PER_POINT' : `QT_${quantityType.toUpperCase()}`)}
							value={formatNumberWithCommas(pendingAmountLot, 2, languageSettings)}
						/>
						<InfoElement title={t('wtr:CREATED_ON')} value={createdOnDate} valueClassName={styles.date} />
						<InfoElement title={tt('TIME')} value={moment(pos.oT).format('HH:mm:ss').toLocaleUpperCase()} />
						<InfoElement title={tt('OPEN_AT')} value={formatNumberWithCommas(pos.prc, decPrec, languageSettings)} />
						<InfoElement title={tt('EXPIRY')} value={expiryDate} valueClassName={styles.date} />
						{/* <InfoElement title={tt('ORDER_STATUS')} value={translateState(pos.state)} /> */}
					</div>
				);
			} else if (matchedPendingOrder.length > 1) {
				return (
					<div className={styles.positionMsg}>
						{matchedPendingOrder.length}{' '}
						{t('wtr:PENDING_CODE_ORDERS', { code: matchedPendingOrder[0].marketItem.displayName })}
					</div>
				);
			}
		};

		//Accordion Title formatting
		const formatOrderDetailHeader = () => {
			if (matchedPendingOrder.length > 1) {
				return `${t('en:TRADES_ORDER_DETAILS')} (${matchedPendingOrder.length})`;
			} else {
				return `${t('en:TRADES_ORDER_DETAILS')}`;
			}
		};

		const formatPositionDetailsHeader = () => {
			if (matchedOpenPosition.length > 1) {
				return `${t('en:TRADES_POSITION_DETAILS')} (${matchedOpenPosition.length})`;
			} else {
				return `${t('en:TRADES_POSITION_DETAILS')}`;
			}
		};

		if (!showOrderInformation) return null;

		if (tradeButtons === 'accountStatus') {
			dashboardContext.showOrderInformation = !dashboardContext.showOrderInformation;
			setShowModal(true);
			appContext.statusModal = true;

			return null;
		}

		const handleClickTrade = () => {
			dashboardContext.showOrderInformation = false;
			// setShowTradeButton(!dashboardContext.showOrderInformation);
		}

		return (
			<div className={styles.dockedContainer}>
				<InstrumentHeader
					showButtons={true}
					resizeHeader={false}
					headerActions={true}
					redirectFromDashboard={redirectFromDashboard}
				/>
				<div className={styles.container}>
					<div className={styles.accordion}>
						<div className={cn(styles.header, styles.item, active === '0' && styles.active)} onClick={openMarketInfo}>
							{t('wtr:MARKET_INFO')}
							<FontAwesomeIcon className={styles.icon} icon={['fas', 'caret-down']} size="2x" />
						</div>
						<div className={cn(styles.body, styles.item, active === '0' && styles.active)}>
							<ModalOrderInformation redirectFromDashboard={redirectFromDashboard} />
						</div>

						<div className={cn(styles.header, styles.item, active === '1' && styles.active)} onClick={openPositionInfo}>
							{formatPositionDetailsHeader()}
							<FontAwesomeIcon className={styles.icon} icon={['fas', 'caret-down']} size="2x" />
						</div>
						<div className={cn(styles.body, styles.item, active === '1' && styles.active)}>{positionMenuState()}</div>

						<div className={cn(styles.header, styles.item, active === '2' && styles.active)} onClick={openOrderInfo}>
							{formatOrderDetailHeader()}
							<FontAwesomeIcon className={styles.icon} icon={['fas', 'caret-down']} size="2x" />
						</div>
						<div className={cn(styles.body, styles.item, active === '2' && styles.active)}>{orderMenuState()}</div>
					</div>
				</div>
				<div className={styles.closeButtonLocation}>
					<CloseButton
						// onClick={() => {
						// 	dashboardContext.showOrderInformation = false;
						// }}
						onClick={() => {
							handleClickTrade()
						}}
					/>
				</div>
			</div>
		);
	},
	(prevProps, nextProps) => {
		return true;
	}
);

export default OrderInformation;
