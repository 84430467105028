import ERROR from '../errorMessages';
import { default as credentials } from '../../setup/credentials';
import { Resolver } from '../../utils/functions/Ioc';
import { default as Dispatcher } from '../../utils/functions/Dispatcher';
import { Subscription } from '../../utils/functions/Observables';
import { AppContextProvider } from '../../contexts/AppContext';
import { InstrumentContextProvider } from '../../contexts/InstrumentContext';
import {
	DashboardContextProvider,
	DefaultWatchList,
	TNotification,
	WatchListItemCodes,
} from '../../contexts/DashboardContext';
import {
	convertTradingSessions,
	getCurrentPrice,
	updateCurrentPrice,
	updatePosition,
} from '../../utils/functions/calculations';

import { MarketsGroupName, MarketsGroupPeriod } from '../../utils/functions/enums';

import {
	getJPAcctIdForTierInfoRequestFromTradingAccounts,
	getUpdatedSubscrInfoObject,
} from '../../utils/functions/subscriptionUtils';

import TTSubscriptionManager from '../../utils/functions/subscriptionManager';

import { getSavedPreferencesForUser } from '../../utils/functions/fillUserPrefLocally';

import { MarginRulesContextProvider } from '../../contexts/MarginRulesContext';

import {
	AccountMarketType,
	AccountTierResponse,
	FeedSubscription,
	MarketItemsInfoRecord,
	MarketItemsRecord,
	MarketWatchCategories,
	MarketWatchIntervals,
	MarketWatchItem,
	MarketWatchItems,
	PreviousDayClosePrice,
	PreviousDayClosePrices,
	RFPConnectPayload,
	RFPNews,
	SubscriptionAction,
	TierUpdateInfo,
	TradingAccount,
	TradingAccountLogin,
	TradingInstruments,
	TradingPosition,
	TradingPositionState,
	TTWatchList,
} from './rfp.types';

import { RFP } from './rfpConstants';
import { default as RfpGateway } from './RfpGateway';

const dispatcher = new Dispatcher();
dispatcher.dispatchInterval = 50;

const subscriptions: Map<string, Subscription<any>> = new Map();
const connectWebSocket = (): RfpGateway => {
	//get RFP Gateway instance
	const rfpGateway = Resolver.resolve(RfpGateway);

	//get app context provider
	const appContextProvider = Resolver.resolve(AppContextProvider);

	//get instrument context provider
	const instrumentContextProvider = Resolver.resolve(InstrumentContextProvider);

	//get dashboard context provider
	const dashboardContextProvider = Resolver.resolve(DashboardContextProvider);

	//get margin rules context provider
	const marginRulesContextProvider = Resolver.resolve(MarginRulesContextProvider);

	//set dispatcher handlers
	dispatcher
		.clearHandlers()
		.setHandler(RFP.queueTradingNews, {
			dispatchInterval: 100,
			dispatchAction: (data: RFPNews[]) => {
				const tradeNews = [...dashboardContextProvider.tradeNews];
				data.forEach((newValue) => {
					const index = tradeNews.findIndex((existingValue) => existingValue.id === newValue.id);
					if (index >= 0) {
						tradeNews.splice(index, 1, newValue);
					} else {
						tradeNews.push(newValue);
					}
				});
				dashboardContextProvider.tradeNews = tradeNews;
			},
		})
		.setHandler(RFP.queueMarketWinNews, {
			dispatchInterval: 100,
			dispatchAction: (data: RFPNews[]) => {
				const freshNewsArr = data[0] as unknown as RFPNews[];

				const tradeNews = [...dashboardContextProvider.tradeNews];
				freshNewsArr.forEach((newValue) => {
					const index = tradeNews.findIndex((existingValue) => existingValue.id === newValue.id);
					if (index >= 0) {
						tradeNews.splice(index, 1, newValue);
					} else {
						tradeNews.push(newValue);
					}
				});
				dashboardContextProvider.tradeNews = tradeNews;
			},
		})
		.setHandler(RFP.queueTradingAccount, {
			dispatchInterval: 0,
			dispatchAction: (data: TradingAccount[]) => {
				const tradingAccounts = [...dashboardContextProvider.tradingAccount];
				data.forEach((newValue) => {
					// this is a workaround because PROD backend is still sending the accountMarketType for Japan Subscription as 0 instead of 3
					// we will replace temporarily the accountMarketType for Japan Accounts
					const accountMarketType =
						appContextProvider.isJapanAccount && newValue.accountMarketType === 0 ? 3 : newValue.accountMarketType;
					const accountData = { ...newValue, accountMarketType };

					const index = tradingAccounts.findIndex((value) => value.providerAccountId === accountData.providerAccountId);
					if (index >= 0) {
						const oldAccount = tradingAccounts[index];
						// Preset some already calculated properties

						accountData.tradingInstruments = oldAccount.tradingInstruments;
						accountData.isLoggedIn = true;

						Object.entries(oldAccount.activePositions).forEach(
							([key, value]) => (accountData.activePositions[key as any] = value)
						);

						Object.entries(oldAccount.closedPositions).forEach(
							([key, value]) => (accountData.closedPositions[key as any] = value)
						);

						accountData.grossProfit = oldAccount.grossProfit;
						accountData.netProfit = oldAccount.netProfit;

						tradingAccounts.splice(index, 1, accountData);
					} else {
						tradingAccounts.push(accountData);
					}
				});

				dashboardContextProvider.tradingAccount = tradingAccounts;
				//TODO: REFACTOR & FIX
				// These conditionals are workaround for this
				// https://thinkmarkets.atlassian.net/browse/WTR-4894
				// On randomly runtime points those 2 arrays are different, in this case,
				// the tradingAccount have the accounts data, but the accountStats does not,
				// that way the request to RFP is not sent, therefore no data for Volume info is received.
				if (appContextProvider.isJapanAccount && tradingAccounts.length) {
					const accountId = getJPAcctIdForTierInfoRequestFromTradingAccounts(tradingAccounts);
					if (accountId && appContextProvider.canFetchSubscrInfo) {
						rfpGateway.send(RFP.queueTierInfo, { accountId: accountId.toString() });
					}
				}
			},
		})
		.setHandler(RFP.queueTradingAccountLogin, {
			dispatchInterval: 100,
			dispatchAction: (data: TradingAccountLogin[]) => {
				if (data.length > 0) {
					data.forEach((account) => {
						dashboardContextProvider.tradingAccount.forEach((tradingAccount) => {
							if (tradingAccount.id === account.accountId) tradingAccount.isLoggedIn = account.loggedOn;
						});
					});
				}

				const notifications = data.map((value) => {
					return {
						isActive: !!value.error,
						message: value.error ? ERROR.RFP_TRADING_ACCOUNT : '',
						type: 'error',
					} as TNotification;
				});
				notifications.forEach((notification) => {
					dashboardContextProvider.notification = notification;
				});
			},
		})
		.setHandler(RFP.queueTierInfo, {
			dispatchInterval: 100,
			dispatchAction: (data: AccountTierResponse[]) => {
				const accountId = getJPAcctIdForTierInfoRequestFromTradingAccounts(dashboardContextProvider.tradingAccount);

				if (accountId !== data[0].accountId) {
					console.debug('Error: accountId received by RFP does not match current account id!');
					return;
				}

				const updateProps = {
					enabled: data[0].enabled,
					TTTier: data[0].tier,
					maxVolume: data[0].maxUnits,
					usedVolume: data[0].usedUnits,
					remainingVolume: data[0].availableUnits,
				};

				appContextProvider.subscriptionInfo = getUpdatedSubscrInfoObject(
					updateProps,
					appContextProvider.subscriptionInfo
				);
			},
		})

		.setHandler(RFP.queueTradingAccountInstruments, {
			dispatchInterval: 100,
			dispatchAction: (data: TradingInstruments[]) => {
				if (data.length > 0) {
					const detailedInfo = [...dashboardContextProvider.detailedInformation];
					data.forEach((newValue) => {
						const index = detailedInfo.findIndex((currentValue) => currentValue.account === newValue.account);
						if (index >= 0) {
							detailedInfo.splice(index, 1, newValue);
						} else {
							detailedInfo.push(newValue);
						}

						dashboardContextProvider.tradingAccount.forEach((account) => {
							if (account.id === newValue.account) {
								account.tradingInstruments = newValue;
							}
						});
					});
					dashboardContextProvider.detailedInformation = detailedInfo;
				}
			},
		})
		.setHandler(RFP.queueMarketItems, {
			dispatchInterval: 100,
			dispatchAction: (data: MarketItemsRecord[]) => {
				// At this point couldn't find a better way to identify the type of Japan Account, but using the watchlist names
				const watchlist = dashboardContextProvider.watchlist;
				data.forEach((value) => {
					dashboardContextProvider.marketItems = value.marketItems;

					let groupingProperty: 'grp' | 'minTier' = 'grp';
					if (
						appContextProvider.isJapanAccount &&
						appContextProvider.tradingMode === 'LIVE' &&
						watchlist &&
						watchlist.some((watchlistItem) => watchlistItem._name === 'Tier 1')
					) {
						groupingProperty = 'minTier';
					}
					instrumentContextProvider.addInstruments(value.marketItems, groupingProperty);
				});
			},
		})
		.setHandler(RFP.queueMarketItemsInfo, {
			dispatchInterval: 250,
			dispatchAction: (data: Array<MarketItemsInfoRecord>) => {
				if (data.length > 0) {
					const marketItemsInfo = data[data.length - 1];
					dashboardContextProvider.marketItemsInfo = marketItemsInfo.marketItemsInfo;
				}
			},
		})
		.setHandler(RFP.queueTradingPositionUpdates, {
			dispatchInterval: 50,
			dispatchAction: (data: TradingPosition[]) => {
				const tradingAccounts = [...dashboardContextProvider.tradingAccount];
				const positions: Record<number, TradingPosition> = { ...dashboardContextProvider.tradingPositions };

				data.forEach((position) => {
					const marketItem = rfpGateway.getMarketItem(position.code, position.f);
					if (marketItem) {
						position.marketItem = marketItem;
					} else {
						// Most probably there is an issue with marketItems collection, please verify with backend team
						console.error(
							'ERROR: marketItem is missing, which means that this position will not be able to be processed and presented correctly.'
						);
					}

					positions[position.posId!] = position;

					tradingAccounts.forEach((account) => {
						if (account.id === position.aId) {
							const oldPos = account.activePositions[position.posId!];
							switch (position.state) {
								case TradingPositionState.open:
								case TradingPositionState.pending:
									if (oldPos) {
										position.additionalSubscriptionPair = oldPos.additionalSubscriptionPair;
										position.cancelRequestSent = oldPos.cancelRequestSent;
									} else {
										TTSubscriptionManager.instance.updatePositionSubscriptions(account, position, true, rfpGateway);
									}
									account.activePositions[position.posId!] = position;

									// Check if there is priceQuote for the position code, do the position updates
									const priceQuote = getCurrentPrice(position.f, position.code, rfpGateway);
									if (priceQuote) {
										updateCurrentPrice(position, priceQuote);
										updatePosition(account, position, rfpGateway);
									}
									break;
								case TradingPositionState.canceled:
									if (oldPos) {
										delete account.activePositions[oldPos.posId!];
									}
									break;
								case TradingPositionState.closed:
									if (oldPos) {
										delete account.activePositions[oldPos.posId!];
									}
									account.closedPositions[position.posId!] = position;
									updatePosition(account, position, rfpGateway);
									break;
							}
						}
					});
				});

				dashboardContextProvider.tradingAccount = tradingAccounts;
				dashboardContextProvider.tradingPositions = positions;
			},
		})
		.setHandler(RFP.queueMarketWatchIntervals, {
			dispatchInterval: 100,
			dispatchAction: (data: MarketWatchIntervals[]) => {
				dashboardContextProvider.marketWatchIntervals = data.length === 1 ? data[0] : data;
			},
		})
		.setHandler(RFP.queueMarketWatchCategories, {
			dispatchInterval: 100,
			dispatchAction: (data: MarketWatchCategories[]) => {
				dashboardContextProvider.marketWatchCategories = data.length === 1 ? data[0] : data;
			},
		})
		.setHandler(RFP.queueMarketWatchItem, {
			dispatchInterval: 100,
			dispatchAction: (data: MarketWatchItem[]) => {
				const marketWatchItem = [...dashboardContextProvider.marketWatchItem];
				data.forEach((newValue) => {
					const index = marketWatchItem.findIndex(
						(currentValue) =>
							currentValue.code === newValue.code && currentValue.intervalLength === newValue.intervalLength
					);
					if (index >= 0) {
						marketWatchItem.splice(index, 1, newValue);
					} else {
						marketWatchItem.push(newValue);
					}

					dashboardContextProvider.updateMarketWatchItem = newValue;
				});
				dashboardContextProvider.marketWatchItem = marketWatchItem;
			},
		})
		.setHandler(RFP.queueMarketWatchItems, {
			dispatchInterval: 100,
			dispatchAction: (data: MarketWatchItems[]) => {
				data.forEach((newValue) => {
					switch (newValue.dynamic_category) {
						case MarketsGroupName.Risers:
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyRisers = [...dashboardContextProvider.marketWatchRisers.daily];
								const index = dailyRisers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									dailyRisers.splice(index, 1, newValue);
								} else {
									dailyRisers.push(newValue);
								}
								dashboardContextProvider.marketWatchRisers.daily = dailyRisers;
							} else {
								const weeklyRisers = [...dashboardContextProvider.marketWatchRisers.weekly];
								const index = weeklyRisers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									weeklyRisers.splice(index, 1, newValue);
								} else {
									weeklyRisers.push(newValue);
								}
								dashboardContextProvider.marketWatchRisers.weekly = weeklyRisers;
							}
							break;
						case MarketsGroupName.RisersSB:
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyRisers = [...dashboardContextProvider.marketWatchRisersSB.daily];
								const index = dailyRisers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									dailyRisers.splice(index, 1, newValue);
								} else {
									dailyRisers.push(newValue);
								}
								dashboardContextProvider.marketWatchRisersSB.daily = dailyRisers;
							} else {
								const weeklyRisers = [...dashboardContextProvider.marketWatchRisersSB.weekly];
								const index = weeklyRisers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									weeklyRisers.splice(index, 1, newValue);
								} else {
									weeklyRisers.push(newValue);
								}
								dashboardContextProvider.marketWatchRisersSB.weekly = weeklyRisers;
							}
							break;
						case MarketsGroupName.Fallers:
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyFallers = [...dashboardContextProvider.marketWatchFallers.daily];
								const index = dailyFallers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									dailyFallers.splice(index, 1, newValue);
								} else {
									dailyFallers.push(newValue);
								}
								dashboardContextProvider.marketWatchFallers.daily = dailyFallers;
							} else {
								const weeklyFallers = [...dashboardContextProvider.marketWatchFallers.weekly];
								const index = weeklyFallers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									weeklyFallers.splice(index, 1, newValue);
								} else {
									weeklyFallers.push(newValue);
								}
								dashboardContextProvider.marketWatchFallers.weekly = weeklyFallers;
							}
							break;
						case MarketsGroupName.FallersSB:
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyFallers = [...dashboardContextProvider.marketWatchFallersSB.daily];
								const index = dailyFallers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									dailyFallers.splice(index, 1, newValue);
								} else {
									dailyFallers.push(newValue);
								}
								dashboardContextProvider.marketWatchFallersSB.daily = dailyFallers;
							} else {
								const weeklyFallers = [...dashboardContextProvider.marketWatchFallersSB.weekly];
								const index = weeklyFallers.findIndex((currentValue) => currentValue.category === newValue.category);
								if (index >= 0) {
									weeklyFallers.splice(index, 1, newValue);
								} else {
									weeklyFallers.push(newValue);
								}
								dashboardContextProvider.marketWatchFallersSB.weekly = weeklyFallers;
							}
							break;
						case 'Most volatile':
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyMostVolatile = [...dashboardContextProvider.marketWatchMostVolatile.daily];
								const index = dailyMostVolatile.findIndex(
									(currentValue) => currentValue.category === newValue.category
								);
								if (index >= 0) {
									dailyMostVolatile.splice(index, 1, newValue);
								} else {
									dailyMostVolatile.push(newValue);
								}
								dashboardContextProvider.marketWatchMostVolatile.daily = dailyMostVolatile;
							} else {
								const weeklyMostVolatile = [...dashboardContextProvider.marketWatchMostVolatile.weekly];
								const index = weeklyMostVolatile.findIndex(
									(currentValue) => currentValue.category === newValue.category
								);
								if (index >= 0) {
									weeklyMostVolatile.splice(index, 1, newValue);
								} else {
									weeklyMostVolatile.push(newValue);
								}
								dashboardContextProvider.marketWatchMostVolatile.weekly = weeklyMostVolatile;
							}
							break;
						case MarketsGroupName.MostVolatileSB:
							if (newValue.items[0].intervalLength === MarketsGroupPeriod.Daily) {
								const dailyMostVolatile = [...dashboardContextProvider.marketWatchMostVolatileSB.daily];
								const index = dailyMostVolatile.findIndex(
									(currentValue) => currentValue.category === newValue.category
								);
								if (index >= 0) {
									dailyMostVolatile.splice(index, 1, newValue);
								} else {
									dailyMostVolatile.push(newValue);
								}
								dashboardContextProvider.marketWatchMostVolatileSB.daily = dailyMostVolatile;
							} else {
								const weeklyMostVolatile = [...dashboardContextProvider.marketWatchMostVolatileSB.weekly];
								const index = weeklyMostVolatile.findIndex(
									(currentValue) => currentValue.category === newValue.category
								);
								if (index >= 0) {
									weeklyMostVolatile.splice(index, 1, newValue);
								} else {
									weeklyMostVolatile.push(newValue);
								}
								dashboardContextProvider.marketWatchMostVolatileSB.weekly = weeklyMostVolatile;
							}
							break;
						default:
							dashboardContextProvider.marketWatchItems = data;
					}

					newValue.items.forEach((element) => {
						dashboardContextProvider.updateMarketWatchItem = element;
					});
				});

				//dashboardContextProvider.marketWatchItems = data
			},
		})
		.setHandler(RFP.queuePreviousDayClosePrice, {
			dispatchInterval: 100,
			dispatchAction: (data: PreviousDayClosePrice[]) => {
				if (data[0].item) {
					const result = data[0].item.reduce((accumulator: PreviousDayClosePrices, { code, prevClose }) => {
						accumulator[code!] = prevClose!;
						return accumulator;
					}, {});

					dashboardContextProvider.addPreviousDayClosePrices(result);
				}
			},
		})
		.setHandler(RFP.queueTierUpdateInfo, {
			dispatchInterval: 100,
			dispatchAction: (data: TierUpdateInfo[]) => {
				// TODO: find some sense to this
				appContextProvider.canFetchSubscrInfo = true;
				// if (data[0]) {
				// 	appContextProvider.canFetchSubscrInfo = true;
				// }
			},
		});

	//unsubscribe any existing subscriptions
	subscriptions.forEach((value, key) => value.unsubscribe());
	subscriptions.clear();

	//create connected handler
	const onConnected = () => {
		dispatcher.start();

		rfpGateway.unsubscribe(RFP.queueTradingPositionUpdates);
		rfpGateway.subscribe(RFP.queueTradingPositionUpdates).then((observable) => {
			const subscription = observable.subscribe((tradingPosition) => {
				dispatcher.dispatch(RFP.queueTradingPositionUpdates, tradingPosition);
			});
			subscriptions.set(RFP.queueTradingPositionUpdates, subscription);
		});

		const DEFAULT_FEED_ID = rfpGateway.config.defaultFeedId || 'VTFeed';
		let feedReq = false;
		rfpGateway.unsubscribe(RFP.queueFeedIds);
		rfpGateway.subscribe(RFP.queueFeedIds).then((observable) => {
			const subscription = observable.subscribe((feedInfos) => {
				feedInfos.feedIds.forEach((feed) => {
					if (!feedReq && feed.feedID === DEFAULT_FEED_ID) {
						feedReq = true;
						const feedSubscription: FeedSubscription = { feedId: feed.feedID };
						rfpGateway.send(RFP.getMarketItems, feedSubscription);
						rfpGateway.send(RFP.getMarketItemsInfo, feedSubscription);
						rfpGateway.send(RFP.getTradingProviders);

						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.Risers,
							intervalLength: MarketsGroupPeriod.Daily,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.RisersSB,
							intervalLength: MarketsGroupPeriod.Daily,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.Fallers,
							intervalLength: MarketsGroupPeriod.Daily,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.FallersSB,
							intervalLength: MarketsGroupPeriod.Daily,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.MostVolatile,
							intervalLength: MarketsGroupPeriod.Daily,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.MostVolatileSB,
							intervalLength: MarketsGroupPeriod.Daily,
						});

						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.Risers,
							intervalLength: MarketsGroupPeriod.Weekly,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.RisersSB,
							intervalLength: MarketsGroupPeriod.Weekly,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.Fallers,
							intervalLength: MarketsGroupPeriod.Weekly,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.FallersSB,
							intervalLength: MarketsGroupPeriod.Weekly,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.MostVolatile,
							intervalLength: MarketsGroupPeriod.Weekly,
						});
						rfpGateway.send(RFP.getMarketWatchSnapshots, {
							categoryName: MarketsGroupName.MostVolatileSB,
							intervalLength: MarketsGroupPeriod.Weekly,
						});

						rfpGateway.subscribe(RFP.queueMarketWatchItem);
						rfpGateway.subscribe(RFP.queueMarketWatchItems);

						// rfpGateway.send(RFP.getMarketWatchIntervals)
						// rfpGateway.send(RFP.getMarketWatchCategories)
						setTimeout(() => {
							rfpGateway.unsubscribe(RFP.queueFeedIds);
							subscription.unsubscribe();
							subscriptions.delete(RFP.queueFeedIds);
						}, 1000);
					}
				});
			});
			subscriptions.set(RFP.queueFeedIds, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTradingNews);
		rfpGateway.subscribe(RFP.queueTradingNews).then((observable) => {
			const subscription = observable.subscribe((rfpNews) => {
				dispatcher.dispatch(RFP.queueTradingNews, rfpNews);
			});
			subscriptions.set(RFP.queueTradingNews, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueMarketWinNews);
		rfpGateway.subscribe(RFP.queueMarketWinNews).then((observable) => {
			const subscription = observable.subscribe((rfpNews) => {
				dispatcher.dispatch(RFP.queueMarketWinNews, rfpNews);
			});
			subscriptions.set(RFP.queueMarketWinNews, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTradingProvider);
		rfpGateway.subscribe(RFP.queueTradingProvider).then((observable) => {
			const subscription = observable.subscribe((tradingProvider) => {
				if (tradingProvider.feedID === 'VTFeed') {
					rfpGateway.send(RFP.getTradingAccounts, tradingProvider.id);
				}
			});
			subscriptions.set(RFP.queueTradingProvider, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTradingAccount);
		rfpGateway.subscribe(RFP.queueTradingAccount).then((observable) => {
			const subscription = observable.subscribe((tradingAccount) => {
				dispatcher.dispatch(RFP.queueTradingAccount, tradingAccount);
				const savedPreferences = getSavedPreferencesForUser(appContextProvider);
				const isLiveMode = tradingAccount.accountType === 'LIVE';
				const lastLoggedAcc = savedPreferences?.user_prefs?.platform?.lastLoggedAct?.[isLiveMode ? 'live' : 'demo'];

				if (dashboardContextProvider.selectedTradingAccount === 0) {
					if (lastLoggedAcc) {
						if (lastLoggedAcc === tradingAccount.providerAccountId) {
							dashboardContextProvider.selectedTradingAccount = tradingAccount.id;
							rfpGateway.send(RFP.tradingAccountLogin, {
								action: 'Login',
								tradingAccountId: tradingAccount.id,
							});
						}
					} else {
						dashboardContextProvider.selectedTradingAccount = tradingAccount.id;
						rfpGateway.send(RFP.tradingAccountLogin, {
							action: 'Login',
							tradingAccountId: tradingAccount.id,
						});
					}
				}
			});
			subscriptions.set(RFP.queueTradingAccount, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTradingAccountLogin);
		rfpGateway.subscribe(RFP.queueTradingAccountLogin).then((observable) => {
			const subscription = observable.subscribe((tradingAccountLogin) => {
				dispatcher.dispatch(RFP.queueTradingAccountLogin, tradingAccountLogin);
			});
			subscriptions.set(RFP.queueTradingAccountLogin, subscription);
		});

		rfpGateway.unsubscribe(RFP.TTWatchList);
		rfpGateway.subscribe(RFP.TTWatchList).then((observable) => {
			const subscription = observable.subscribe((watchList) => {
				if (watchList.items.length === 0) {
					if (!appContextProvider.isJapanAccount) {
						rfpGateway.send(RFP.requestTTWatchList, {
							isoCode: 'XX',
						});
					}
				} else {
					if (watchList.items[0].order !== 0) {
						if (!appContextProvider.isJapanAccount) {
							rfpGateway.send(RFP.requestTTWatchList, {
								isoCode: 'XX',
							});
						}
					}
					const newWatchList: DefaultWatchList = { ...watchList };
					newWatchList.items.forEach((item) => {
						// Check if codes_csv is a string, if so convert it to an array of WatchListItemCodes
						if (typeof item.codes_csv === 'string' || item.codes_csv instanceof String) {
							const codesArray: WatchListItemCodes[] = [];
							const codes = item.codes_csv.split(',');
							codes.forEach((item: string) => {
								let newCode = { _code: item };
								codesArray.push(newCode);
							});
							item.codes_csv = codesArray;
						}
					});
					dashboardContextProvider.defaultWatchLists = [newWatchList, ...dashboardContextProvider.defaultWatchLists];

					dispatcher.dispatch(RFP.TTWatchList, watchList);
				}
			});
			subscriptions.set(RFP.TTWatchList, subscription);
		});

		rfpGateway.unsubscribe(RFP.TTMarginRules);
		rfpGateway.subscribe(RFP.TTMarginRules).then((observable) => {
			const subscription = observable.subscribe((currentMarginRules) => {
				// console.info('observable.subscribe((currentMarginRules', { currentMarginRules });

				// Create a new Context for this, because current architecture doesn't allow to import stores here.
				marginRulesContextProvider.accountId = currentMarginRules.accountId;
				marginRulesContextProvider.marginRules = currentMarginRules.items;
				dispatcher.dispatch(RFP.TTMarginRules, currentMarginRules);
			});
			subscriptions.set(RFP.TTMarginRules, subscription);
		});

		rfpGateway.unsubscribe(RFP.futureMargin);
		rfpGateway.subscribe(RFP.futureMargin).then((observable) => {
			const subscription = observable.subscribe((currentFutureMargin) => {
				// Created a new Context for this, because current architecture doesn't allow to import stores here.
				marginRulesContextProvider.futureMarginReqId = currentFutureMargin.reqId;
				marginRulesContextProvider.futureMarginValue = currentFutureMargin.margin;
				dispatcher.dispatch(RFP.futureMargin, currentFutureMargin);
			});
			subscriptions.set(RFP.futureMargin, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTierInfo);
		rfpGateway.subscribe(RFP.queueTierInfo).then((observable) => {
			const subscription = observable.subscribe((tierInfo) => {
				dispatcher.dispatch(RFP.queueTierInfo, tierInfo);
			});
			subscriptions.set(RFP.queueTierInfo, subscription);
		});

		rfpGateway.unsubscribe(RFP.rfpServerNames);
		rfpGateway.subscribe(RFP.rfpServerNames).then((observable) => {
			const subscription = observable.subscribe((serverNames) => {
				dashboardContextProvider.rfpServerNames = serverNames.rfpServerNames;
				dispatcher.dispatch(RFP.rfpServerNames, serverNames);
			});
			subscriptions.set(RFP.rfpServerNames, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTradingAccountInstruments);
		rfpGateway.subscribe(RFP.queueTradingAccountInstruments).then((observable) => {
			const subscription = observable.subscribe((tradingInstruments) => {
				dispatcher.dispatch(RFP.queueTradingAccountInstruments, tradingInstruments);
			});
			subscriptions.set(RFP.queueTradingAccountInstruments, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueMarketItems);
		rfpGateway.subscribe(RFP.queueMarketItems).then((observable) => {
			const subscription = observable.subscribe((marketItemsRecord) => {
				dispatcher.dispatch(RFP.queueMarketItems, marketItemsRecord);
			});
			subscriptions.set(RFP.queueMarketItems, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueMarketItemsInfo);
		rfpGateway.subscribe(RFP.queueMarketItemsInfo).then((observable) => {
			const subscription = observable.subscribe((marketItemInfo) => {
				dispatcher.dispatch(RFP.queueMarketItemsInfo, marketItemInfo);
			});
			subscriptions.set(RFP.queueMarketItemsInfo, subscription);
		});

		//subscribe for quote updates
		rfpGateway.unsubscribe(RFP.queueQuotes);
		rfpGateway.subscribe(RFP.queueQuotes).then((observable) => {
			const subscription = observable.subscribe((priceQuote) => {
				// Make sure that the price quote is updated
				rfpGateway.setPriceQuote(priceQuote);

				// Define current price for each position
				dashboardContextProvider.tradingAccount.forEach((account) => {
					if (DEFAULT_FEED_ID === priceQuote.f) {
						for (const positionId in account.activePositions) {
							const position = account.activePositions[positionId];
							if (priceQuote.c === position.code) {
								updateCurrentPrice(position, priceQuote);
								updatePosition(account, position, rfpGateway);
							} else if (position.additionalSubscriptionPair.has(priceQuote.c)) {
								updatePosition(account, position, rfpGateway);
							}
						}
					}
				});
			});
			subscriptions.set(RFP.queueQuotes, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueInfos);
		rfpGateway.subscribe(RFP.queueInfos).then((observable) => {
			const subscription = observable.subscribe((info) => {
				// console.debug(`${RFP.queueInfos}: `, info);
			});
			subscriptions.set(RFP.queueInfos, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueSessionTimes);
		rfpGateway.subscribe(RFP.queueSessionTimes).then((observable) => {
			const subscription = observable.subscribe((info) => {
				const defaultFeedId = rfpGateway.config.defaultFeedId || 'VTFeed';
				const marketItem = rfpGateway.mapMarketItems.get(`${defaultFeedId}-${info.symbol}`);
				if (marketItem) {
					marketItem.tradingSessions = convertTradingSessions(info);

					// If the selected instrument is the same as the updated market item, update the selected instrument
					if (
						dashboardContextProvider &&
						dashboardContextProvider.selectedInstrument &&
						dashboardContextProvider.selectedInstrument.code === marketItem.code
					) {
						dashboardContextProvider.selectedInstrument = marketItem;
					}
				}
			});

			subscriptions.set(RFP.queueSessionTimes, subscription);
		});

		// rfpGateway.unsubscribe(RFP.queueMarketWatchIntervals)
		// rfpGateway.subscribe(RFP.queueMarketWatchIntervals).then((observable) => {
		// 	const subscription = observable.subscribe((mktwatchIntervals) => {
		// 		dispatcher.dispatch(RFP.queueMarketWatchIntervals, mktwatchIntervals)
		// 	})
		// 	subscriptions.set(RFP.queueMarketWatchIntervals, subscription)
		// })

		rfpGateway.unsubscribe(RFP.queueMarketWatchItem);
		rfpGateway.subscribe(RFP.queueMarketWatchItem).then((observable) => {
			const subscription = observable.subscribe((mktwatchItem) => {
				dispatcher.dispatch(RFP.queueMarketWatchItem, mktwatchItem);
			});
			subscriptions.set(RFP.queueMarketWatchItem, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueMarketWatchItems);
		rfpGateway.subscribe(RFP.queueMarketWatchItems).then((observable) => {
			const subscription = observable.subscribe((mktwatchItems) => {
				dispatcher.dispatch(RFP.queueMarketWatchItems, mktwatchItems);
			});
			subscriptions.set(RFP.queueMarketWatchItems, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueErrors);
		rfpGateway.subscribe(RFP.queueErrors).then((observable) => {
			const subscription = observable.subscribe((error) => {
				console.debug(`${RFP.queueErrors}: `, error);
			});
			subscriptions.set(RFP.queueErrors, subscription);
		});

		rfpGateway.unsubscribe(RFP.queuePreviousDayClosePrice);
		rfpGateway.subscribe(RFP.queuePreviousDayClosePrice).then((observable) => {
			const subscription = observable.subscribe((previousDayClosePrice) => {
				dispatcher.dispatch(RFP.queuePreviousDayClosePrice, previousDayClosePrice);
			});
			subscriptions.set(RFP.queuePreviousDayClosePrice, subscription);
		});

		rfpGateway.unsubscribe(RFP.queueTierUpdateInfo);
		rfpGateway.subscribe(RFP.queueTierUpdateInfo).then((observable) => {
			const subscription = observable.subscribe((tierUpdateInfo) => {
				dispatcher.dispatch(RFP.queueTierUpdateInfo, tierUpdateInfo);
			});
			subscriptions.set(RFP.queueTierUpdateInfo, subscription);
		});

		//get feed IDs (should be first call after connection is established)
		rfpGateway.send(RFP.getFeedIds);

		//TODO: should msgCount be in config rather than hard-coded?
		setTimeout(() => {
			// Japan accounts are fed news by a dedicated provider, not by our main provider
			// The 'Japanese' news message format is then 'cast'/'mapped' on our FE
			// in order to fit the general RFP news structure expected by the UI
			if (appContextProvider.isJapanAccount) {
				rfpGateway.send(RFP.manageMarketWinNews, { subscribe: true });
				return;
			}

			rfpGateway.send(RFP.manageTradingNews, { action: SubscriptionAction.Subscribe, msgCount: 90 });
		}, 1000);
	};

	rfpGateway
		.removeAllListeners('connected')
		.on('connected', onConnected)
		.removeAllListeners('socketError')
		.on('socketError', () => {})
		.removeAllListeners('disconnected')
		.on('disconnected', () => dispatcher.stop());

	if (!rfpGateway.connected) {
		rfpGateway.connect(() => {
			return new Promise((resolve, reject) => {
				const rfpCreds = credentials.getCredentials();
				const loginCred = {
					...{ username: null, password: null, tradingMode: null, enableSSO: false, sso_token: '' },
					...rfpCreds,
				} as RFPConnectPayload;
				resolve({
					username: loginCred.username,
					password: loginCred.password,
					tradingMode: loginCred.tradingMode,
					enableSSO: loginCred.enableSSO,
					sso_token: loginCred.sso_token,
					skip_tfbo_login: loginCred.skip_tfbo_login,
					captcha_response: loginCred.captcha_response,
				});
			});
		});
	} else {
		onConnected();
	}

	return rfpGateway;
};

export default connectWebSocket;
