import React, { Fragment, useState, useEffect, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { uniqueRecord } from '../../../../utils/functions/uniqueArticle';
import { RFPNews, TradingPosition, TradingPositionState } from '../../../../gateways/RfpGateway/rfp.types';
import useShortTranslation from '../../../../utils/hooks/useShortTranslation';

import { default as useForceRerender } from '../../../../utils/hooks/useForceRerender';
import { default as usePromiseFactory } from '../../../../utils/hooks/usePromiseFactory';
import { default as useObservable } from '../../../../utils/hooks/useObservable';
import { default as DashboardContext } from '../../../../contexts/DashboardContext';
import { getKeywords } from '../../Dashboard/Newsfeed/NewsKeywords';
import AppContext from '../../../../contexts/AppContext';

import JapanNewsDisclaimer from '../../../components/JapanNewsDisclaimer/JapanNewsDisclaimer';
import WtrDropdown from '../../../components/WtrDropdown/WtrDropdown';

import useSelectedTradingAccount from '../../../../utils/hooks/useSelectedTradingAccount';
import watchListStore from '../../../../store/WatchListStore/watchListStore';
import authStore from '../../../../store/authStore';

import NewsArticle from './NewsArticle';
import { dropdownTypes } from './dropdown';

import styles from './NewsWidgetStyles.module.scss';

const NewsWidget = ({ withDetails = false }: { withDetails?: boolean }) => {
	const dashboardContext = useContext(DashboardContext);
	const appContext = useContext(AppContext);
	const promiseFactory = usePromiseFactory();
	const forceRerender = useForceRerender();

	const [filterOption, setFilterOption] = useState<String>('All news');
	const [listOfArticles, setListOfArticles] = useState<RFPNews | any>(dashboardContext.tradeNews);
	const [matchingArticles, setMatchingArticles] = useState<RFPNews[] | any>(dashboardContext.tradeNews);
	const [selectedArticle, setSelectedArticle] = useState<number>(0);

	const { t } = useTranslation();
	const tt = useShortTranslation('wtr:');

	const tradeNews = dashboardContext.tradeNews;
	const tradingPositions = dashboardContext.tradingPositions;
	const selectedTradingAccount = useSelectedTradingAccount();
	const tradingMode = authStore.use.tradingMode();
	const getWatchlists = watchListStore.use.getWatchlists();
	const allWatchlists = getWatchlists(tradingMode);

	useObservable(appContext.getPropertyChangeStream('appTheme', 'tradingMode'), () => {
		promiseFactory.throttle('appContext.propertyChanged', 100).then(() => {
			forceRerender();
		});
	});

	useObservable(
		dashboardContext.getPropertyChangeStream('tradeNews', 'tradingPositions', 'watchlist', 'tradingAccount'),
		() => {
			promiseFactory.throttle('dashboardContext.propertyChanged', 100).then(() => {
				forceRerender();
			});
		}
	);

	const dropdownOptions: dropdownTypes[] = [
		{
			key: 'All news',
			text: tt('ALL_NEWS'),
			value: 'All news',
			content: tt('ALL_NEWS'),
		},
		{
			key: 'Portfolio',
			text: t('en:Portfolio'),
			value: 'Portfolio',
			content: t('en:Portfolio'),
		},
		{
			key: 'Watchlists',
			text: t('en:Watchlists'),
			value: 'Watchlists',
			content: t('en:Watchlists'),
		},
	];

	const handleChange = (_: any, { value }: any): void => {
		setFilterOption(value);
	};

	const formatCode = (code: string): string => {
		return code.slice(0, 3) + '/' + code.slice(3, 6);
	};

	useEffect(() => {
		if (filterOption === 'All news') {
			setListOfArticles(tradeNews);
		} else if (filterOption === 'Watchlists' && allWatchlists) {
			setListOfArticles(allWatchlists);
		} else if (filterOption === 'Portfolio') {
			setListOfArticles([]);
		}
	}, [filterOption, dashboardContext.tradeNews]);

	useEffect(() => {
		if (filterOption === 'All news') {
			setMatchingArticles(
				tradeNews.sort((a: RFPNews, b: RFPNews) => {
					return +b.time - +a.time;
				})
			);
		}

		if (filterOption === 'Watchlists') {
			let matchingArticles: RFPNews[] = [];
			if (allWatchlists) {
				const watchlistCodes = allWatchlists.flatMap((watchlist) => watchlist.instruments);
				const formattedCodes = watchlistCodes.map((code) => ({
					code,
					formattedCode: formatCode(code),
					keywords: getKeywords(code),
				}));

				tradeNews.forEach((news) => {
					const bodyText = news.body.split(/<p>| /);
					formattedCodes.forEach(({ code, formattedCode, keywords }) => {
						if (
							bodyText.includes(formattedCode) ||
							bodyText.includes(code) ||
							bodyText.some((text) => text.includes(code) || text.includes(formattedCode)) ||
							keywords.some((keyword) => bodyText.includes(keyword))
						) {
							matchingArticles.push(news);
						}
					});
				});
			}
			const unique = uniqueRecord(matchingArticles);
			const sorted = unique.sort((a: RFPNews, b: RFPNews) => {
				return +b.time - +a.time;
			});
			setMatchingArticles(sorted);
		}

		if (filterOption === 'Portfolio') {
			const currentPositions = Object.values(tradingPositions).filter(
				(order: TradingPosition) =>
					order.aId === selectedTradingAccount?.id &&
					(order.state === TradingPositionState.open || order.state === TradingPositionState.pending)
			);

			let matchingArticles: RFPNews[] = [];

			//FIXME: optimisation
			for (let currentPosition of currentPositions) {
				const code = currentPosition.code;
				const formattedCode = formatCode(code);
				const keywords = getKeywords(code);
				for (let j = 0; j < tradeNews.length; j++) {
					const bodyText: string[] = tradeNews[j].body.split(/<p>| /);
					for (let k = 0; k < bodyText.length; k++) {
						if (
							bodyText[k] === formattedCode ||
							bodyText[k] === code ||
							bodyText[k].includes(code) ||
							bodyText[k].includes(formattedCode) ||
							keywords.includes(bodyText[k].toUpperCase())
						) {
							matchingArticles.push(tradeNews[j]);
						}
					}
				}
			}
			const unique = uniqueRecord(matchingArticles);
			const sorted = unique.sort((a: RFPNews, b: RFPNews) => {
				return +b.time - +a.time;
			});
			setMatchingArticles(sorted);
		}
	}, [listOfArticles, selectedTradingAccount]);

	return (
		<>
			<div className={styles.header}>
				<div className={styles.actions}>
					<div className={styles.title}>{t('en:NEWS')}</div>
					<span className={styles.span}>
						{tt('SHOW')}{' '}
						<WtrDropdown
							inline
							className={styles.dropdown}
							options={dropdownOptions}
							defaultValue={dropdownOptions[0].value}
							onChange={handleChange}
						/>
					</span>
				</div>
			</div>
			{/* <div className={styles.contentsAndDisclaimer}> */}
			<div className={styles.contents}>
				<div className={cn(styles.wrapper, withDetails && styles.halfWidth)}>
					<div className={cn(styles.contentsAndDisclaimer, withDetails && styles.width50)}>
						<div className={cn(appContext.isJapanAccount ? styles.jpNewsWrapper : styles.newsWrapper)}>
							{matchingArticles.length > 0 && matchingArticles !== undefined ? (
								matchingArticles.map((article: RFPNews, index: number) => {
									return (
										<Fragment key={article.id}>
											<NewsArticle
												className={index === selectedArticle && appContext.isJapanAccount ? styles.selected : ''}
												headline={article.headline}
												time={article.time}
												id={article.id}
												onClick={withDetails ? () => setSelectedArticle(index) : undefined}
											/>
										</Fragment>
									);
								})
							) : (
								<div className={styles.emptyContainer}>
									<FontAwesomeIcon className={styles.emptyIcon} icon={['fal', 'newspaper']} />
									<div className={styles.message}>{tt('WTR_NO_NEWS')}</div>
								</div>
							)}
						</div>
						{matchingArticles.length > 0 && (
							<div className={styles.disclaimerMessageWrapper}>
								<JapanNewsDisclaimer />
							</div>
						)}
					</div>
					{withDetails && (
						<div className={cn(styles.articleDetails, styles.width50)}>
							{matchingArticles[selectedArticle] && (
								<>
									<div className={styles.heading}>{matchingArticles[selectedArticle].headline}</div>
									<div className={styles.textContainer}>
										<div
											className={styles.bodyText}
											dangerouslySetInnerHTML={{ __html: matchingArticles[selectedArticle].body }}
										></div>
									</div>
								</>
							)}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default NewsWidget;
