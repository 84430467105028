import React, { useContext } from 'react';

import { Modal } from 'react-bootstrap';

import { default as useObservable } from '../../../utils/hooks/useObservable';
import { default as AppContext } from '../../../contexts/AppContext';
import { default as DashboardContext } from '../../../contexts/DashboardContext';
import { default as useForceRerender } from '../../../utils/hooks/useForceRerender';

import userGuideGeneralPDF from '../../../docs/Web-User-Guide.pdf';
// import userGuideJapanPDF from '../../../../public/docs/Web-User-Guide-Japan.pdf';

import styles from './UserGuideModal.module.scss';

const UserGuide = () => {
	const appContext = useContext(AppContext);
	const dashboardContext = useContext(DashboardContext);
	const forceRerender = useForceRerender();

	useObservable(appContext.getPropertyChangeStream('isJapanAccount'), async (change) => {
		forceRerender();
	});

	useObservable(dashboardContext.getPropertyChangeStream('userGuide'), async (change) => {
		forceRerender();
	});

	if (!dashboardContext.userGuide) {
		return null;
	}

	const userGuideJapanPDF = require('../../../docs/Web-User-Guide-Japan.pdf');

	const fileSrc = appContext.isJapanAccount ? userGuideJapanPDF : undefined;

	return (
		<Modal
			dialogClassName={styles.modalSize}
			centered
			show={dashboardContext.userGuide}
			onHide={() => (dashboardContext.userGuide = false)}
		>
			<Modal.Body className={styles.modalBody}>
				<embed src={fileSrc} width="100%" height="720px" type="application/pdf" />
				{/*<iframe src={fileSrc} width="100%" height="720px" title="user guide file"/>*/}
			</Modal.Body>
		</Modal>
	);
};

export default UserGuide;
