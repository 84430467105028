import { immer } from 'zustand/middleware/immer';
import { persist } from 'zustand/middleware';

import { create } from '../create';
import createSelectors from '../createSelectors';
import { TradingMode } from '../../gateways/RfpGateway/rfp.types';
import {
	DEFAULT_FEED_ID,
	WATCHLIST_SORT_ORDER,
	WATCHLIST_SORT_VALUE,
	WatchlistSortOrder,
	WatchlistSortValue,
} from '../../utils/functions/WatchlistUtils';

export interface WatchListData {
	id: string;
	name: string;
	feedId: string;
	sortOrder: WatchlistSortOrder;
	sortValue: WatchlistSortValue;
	instruments: string[];
}

interface WatchListStoreActions {
	setCurrentWatchList: (currentWatchList: any) => void;
	isWatchlistNameExists: (tradingMode: TradingMode, name: string) => boolean;
	hasWatchlists: (tradingMode: TradingMode) => boolean;

	addEmptyWatchlist: (tradingMode: TradingMode, name: string) => void;
	addWatchlist: (tradingMode: TradingMode, data: WatchListData) => void;
	getSelectedWatchlist: (tradingMode: TradingMode) => WatchListData | null;
	getWatchlist: (tradingMode: TradingMode, name: string) => WatchListData | null;
	getWatchlists: (tradingMode: TradingMode) => WatchListData[] | null;
	getWatchlistsContainsInstrument: (tradingMode: TradingMode, instrument: string) => WatchListData[] | null;
	setWatchlist: (tradingMode: TradingMode, data: WatchListData) => void;
	setWatchlists: (tradingMode: TradingMode, watchlists: WatchListData[]) => void;
	setWatchlistsForAllTradingModes: (watchlists: WatchListData[]) => void;
	setSortOrder: (tradingMode: TradingMode, name: string, sortOrder: WatchlistSortOrder) => void;
	removeWatchlist: (tradingMode: TradingMode, name: string) => void;
	addInstruments: (tradingMode: TradingMode, name: string, instruments: string[]) => void;
	setInstruments: (tradingMode: TradingMode, name: string, instruments: string[]) => void;
	reorderWatchlistInstruments: (tradingMode: TradingMode, name: string, fromIndex: number, toIndex: number) => void;
	removeInstruments: (tradingMode: TradingMode, name: string, instruments: string[]) => void;
	removeAllInstruments: (tradingMode: TradingMode, name: string) => void;
	moveInstruments: (
		tradingMode: TradingMode,
		fromWatchlist: string,
		destinationWatchlist: string,
		instruments: string[]
	) => void;
	isInstrumentExistsInWatchlist: (tradingMode: TradingMode, name: string, instrument: string) => boolean;
	isInstrumentExistsInAnyWatchlist: (tradingMode: TradingMode, instrument: string) => boolean;
	watchlistsCount: (tradingMode: TradingMode) => number;
	watchlistInstrumentCount: (tradingMode: TradingMode, name: string) => number;
	getWatchlistInstruments: (tradingMode: TradingMode, name: string) => string[];
	renameWatchlist: (tradingMode: TradingMode, oldName: string, newName: string) => void;
	reset: () => void;
	setShowWatchListPanel: (show: boolean) => void;
}

export interface WatchListStoreValues {
	currentWatchList: string;
	showWatchListPanel: boolean;
	// key is trading mode and value is array of watchlist items
	watchlists: { [key: string]: WatchListData[] };
}

export const initialStateWatchListStore: WatchListStoreValues = {
	currentWatchList: '',
	showWatchListPanel: true,
	watchlists: {},
};

export type WatchListStore = WatchListStoreValues & WatchListStoreActions;

const watchListStore = create<WatchListStore>()(
	persist(
		immer((set: any, get: any) => ({
			...initialStateWatchListStore,

			setCurrentWatchList: (currentWatchList: string) =>
				set({
					currentWatchList,
				}),

			watchlistsCount: (tradingMode) => {
				const watchlists = get().watchlists[tradingMode];
				return watchlists ? watchlists.length : 0;
			},

			watchlistInstrumentCount: (tradingMode, name) => {
				const watchlist = get().getWatchlist(tradingMode, name);
				return watchlist ? watchlist.instruments.length : 0;
			},

			getWatchlistInstruments: (tradingMode, name) => {
				const watchlist = get().getWatchlist(tradingMode, name);
				return watchlist ? watchlist.instruments : [];
			},

			getWatchlistsContainsInstrument: (tradingMode, instrument) => {
				const watchlists = get().watchlists[tradingMode];
				if (watchlists) {
					return watchlists.filter((watchlist: WatchListData) => watchlist.instruments.includes(instrument));
				}
				return null;
			},

			hasWatchlists: (tradingMode) => {
				const watchlists = get().watchlists[tradingMode];
				return watchlists;
			},

			isWatchlistNameExists: (tradingMode, name) => {
				return get().getWatchlist(tradingMode, name);
			},

			getWatchlist: (tradingMode, name) => {
				const watchlists = get().watchlists[tradingMode];
				if (watchlists) {
					return watchlists.find((watchlist: WatchListData) => watchlist.name === name);
				}
				return null;
			},

			getWatchlists: (tradingMode) => {
				return get().watchlists[tradingMode];
			},

			getSelectedWatchlist: (tradingMode) => {
				return get().getWatchlist(tradingMode, get().currentWatchList);
			},

			addEmptyWatchlist: (tradingMode, name) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode] || [];
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: [
								...watchlists,
								{
									id: name,
									name,
									feedId: DEFAULT_FEED_ID,
									sortOrder: WATCHLIST_SORT_ORDER.NONE,
									sortValue: WATCHLIST_SORT_VALUE.INSTRUMENT,
									instruments: [],
								} as WatchListData,
							],
						},
					};
				});
			},
			addWatchlist: (tradingMode, data) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode] || [];
					const uniqueWatchlists = [...watchlists, data].filter(
						(watchlist, index, self) => index === self.findIndex((w) => w.id === watchlist.id)
					);
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: uniqueWatchlists,
						},
					};
				});
			},
			setWatchlist: (tradingMode, data) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === data.name) {
							return data;
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			setWatchlists: (tradingMode, watchlists) => {
				set((state: WatchListStore) => {
					const uniqueWatchlists = watchlists.filter(
						(watchlist, index, self) => index === self.findIndex((w) => w.id === watchlist.id)
					);
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: uniqueWatchlists,
						},
					};
				});
			},
			setWatchlistsForAllTradingModes: (watchlists) => {
				get().setWatchlists('LIVE', watchlists);
				get().setWatchlists('DEMO', watchlists);
			},
			removeWatchlist: (tradingMode, name) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].filter((item) => item.name !== name);
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			setSortOrder: (tradingMode, name, sortOrder) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							return {
								...item,
								sortOrder,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			addInstruments: (tradingMode, name, instruments) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							return {
								...item,
								instruments: item.instruments.concat(instruments),
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			setInstruments: (tradingMode, name, instruments) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							return {
								...item,
								instruments,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			reorderWatchlistInstruments: (tradingMode, name, fromIndex, toIndex) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							const instruments = item.instruments.slice();
							const [removed] = instruments.splice(fromIndex, 1);
							instruments.splice(toIndex, 0, removed);
							return {
								...item,
								instruments,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			removeInstruments: (tradingMode, name, instruments) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							const filteredInstruments = item.instruments.filter(
								(inst) => !instruments.some((instrument) => instrument === inst)
							);
							return {
								...item,
								instruments: filteredInstruments,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			removeAllInstruments: (tradingMode, name) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === name) {
							return {
								...item,
								instruments: [],
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			moveInstruments: (tradingMode, fromWatchlist, destinationWatchlist, instruments) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === fromWatchlist) {
							const fromWatchlistInstruments = item.instruments.filter(
								(inst) => !instruments.some((instrument) => instrument === inst)
							);
							return {
								...item,
								instruments: fromWatchlistInstruments,
							};
						}
						if (item.name === destinationWatchlist) {
							const destinationWatchlistInstruments = item.instruments.concat(instruments);
							return {
								...item,
								instruments: destinationWatchlistInstruments,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			isInstrumentExistsInWatchlist: (tradingMode, name, instrument) => {
				const watchlist = get().getWatchlist(tradingMode, name);
				return watchlist ? watchlist.instruments.includes(instrument) : false;
			},
			isInstrumentExistsInAnyWatchlist: (tradingMode, instrument) => {
				const watchlists = get().watchlists[tradingMode];
				if (watchlists) {
					return watchlists.some((watchlist: WatchListData) => watchlist.instruments.includes(instrument));
				}
				return false;
			},
			renameWatchlist: (tradingMode, oldName, newName) => {
				set((state: WatchListStore) => {
					const watchlists = state.watchlists[tradingMode].map((item) => {
						if (item.name === oldName) {
							return {
								...item,
								id: newName,
								name: newName,
							};
						}
						return item;
					});
					return {
						watchlists: {
							...state.watchlists,
							[tradingMode]: watchlists,
						},
					};
				});
			},
			reset: () => set({ ...initialStateWatchListStore }),

			setShowWatchListPanel: (show: boolean) =>
				set({
					showWatchListPanel: show,
				}),
		})),
		{
			name: 'watchlist-store', // unique name
			partialize: (state) => ({
				currentWatchList: state.currentWatchList,
				showWatchListPanel: state.showWatchListPanel,
				// Exclude `watchlists`
			}),
		}
	)
);

export default createSelectors(watchListStore);
